import React, { useMemo, useState } from "react";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Input,
  Form,
  Select,
  Checkbox,
  message,
  Button,
  Tooltip,
} from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";
import postResetUserPassword from "../../../api/password/postResetUserPassword";
import postResendRegisterConfirmation from "../../../api/auth/postResendRegisterConfirmation";
import BackButton from "../../backButton/BackButton";
import DeleteUser from "../DeleteUser";
import HrDivider from "../../divider/Divider";
import Teaser from "../../teaser/Teaser";
import {
  routePathNames,
  messageData,
  deliverDateOptions,
} from "../../../appConfig";
import { CompanyUserProfile } from "../../../types/companyUserProfile";
import { ReactComponent as Arrow } from "../../../static/svg/arrow.svg";
import { RootState } from "../../../types/rootState";

const { Option } = Select;
interface Props {
  userData?: CompanyUserProfile;
  setUserData: (userData: CompanyUserProfile) => void;
  type?: "edit" | "add";
}

type SizeType = Parameters<typeof Form>[0]["size"];

/**
 * user form component with encapsulated logics
 * @param userData {object}
 * @param superAdminId {string}
 * @param type {edit | add}
 * @param setUserData {function}
 */
function UserProfileForm({ userData, setUserData, type = "edit" }: Props) {
  const { isSuperAdmin: stateIsSuperAdmin, id: superAdminId } = useSelector(
    (state: RootState) => state.userData
  );
  const {
    firstName,
    lastName,
    email,
    salutation,
    phone,
    uuid,
    isSuperAdmin,
    isImported,
    registered,
    favouriteListDefaultSorting,
    deliveryDates,
    showPortalTips,
  } = userData;

  const { deliveryDates: businessUnitDeliveryDates = [] } = useSelector(
    (state: RootState) => state.userData?.businessUnit || {}
  );

  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );
  const [isResettingPassword, setIsResettingPassword] =
    useState<boolean>(false);
  const [isResendingConfirmation, setIsResendingConfirmation] =
    useState<boolean>(false);

  const isUnconfirmedUser = useMemo(() => {
    return isImported === false && registered === null;
  }, [isImported, registered]);

  const customerDeliveryDateOptions = useMemo(() => {
    return deliverDateOptions.map((option: any) => {
      return {
        ...option,
        disabled: !businessUnitDeliveryDates.includes(option?.value),
      };
    });
  }, [businessUnitDeliveryDates]);

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  const handleUserData = (values: any) => {
    setUserData(values);
  };

  const resetPassword = async () => {
    setIsResettingPassword(true);
    const response = await postResetUserPassword(email);
    setIsResettingPassword(false);

    if (response === 200 || response === 204) {
      message.success(messageData.success.auth.updatePassword);
    } else {
      message.error(messageData.error.auth.updatePassword);
    }
  };

  const resendRegisterConfirmation = async () => {
    setIsResendingConfirmation(true);
    const response = await postResendRegisterConfirmation(uuid);
    setIsResendingConfirmation(false);

    if (response === 204) {
      message.success(messageData.success.auth.resendRegisterConfirmation);
    } else {
      message.error(messageData.error.auth.resendRegisterConfirmation);
    }
  };

  const headline = type === "add" ? "Nutzer anlegen" : "Nutzer bearbeiten";

  return (
    <>
      <BackButton />
      <Row gutter={{ xs: 16, lg: 32 }} justify="space-between">
        <Col xs={12} lg={8} className="">
          <header>
            <h1 className="newsTitle">
              {!stateIsSuperAdmin || superAdminId === uuid
                ? "Mein Konto"
                : headline}
            </h1>
            {(!stateIsSuperAdmin || superAdminId === uuid) && (
              <h2 className="mt-m">Persönliche Einstellungen</h2>
            )}
          </header>
        </Col>
        <Col span={12} className="hidden-lg-up">
          <HrDivider size={2} spacingTop="s" className="mb-m" />
        </Col>
        <Col xs={12} lg={4} className="flex justify-end items-center">
          {stateIsSuperAdmin && superAdminId !== uuid && type === "edit" && (
            <DeleteUser userData={userData} />
          )}
        </Col>
      </Row>
      <div className="edgeToEdge">
        <Row gutter={{ xs: 16, lg: 32 }}>
          <Teaser title="" headlineLevel={2} className="titleFormMyAccount">
            {!stateIsSuperAdmin && type === "edit" && (
              <Row>
                <Col xs={12} lg={8}>
                  <p>
                    Für Änderungen an Name, Anrede oder Telefonnummer wende dich
                    bitte an deinen Administrator oder, wenn dein Nutzer direkt
                    von Weiling angelegt wurde, an deinen Kundenberater.
                  </p>
                </Col>
              </Row>
            )}
            {type === "add" && <h3 className="mb-m">Nutzerdaten</h3>}
            <Form
              name="companyUserProfile"
              className="companyUserProfileForm"
              layout="vertical"
              initialValues={{
                email,
                lastName,
                firstName,
                phone,
                salutation: !salutation ? "" : salutation,
                isSuperAdmin,
                favouriteListDefaultSorting,
                deliveryDates,
                showPortalTips: showPortalTips ?? true,
              }}
              onFinish={handleUserData}
              onValuesChange={onFormLayoutChange}
              size={componentSize as SizeType}
            >
              <Form.Item
                label="E-Mail/Benutzername *"
                name="email"
                required={false}
                rules={[
                  {
                    type: "email",
                    message: "Bitte eine valide E-mail-Addresse eingeben!",
                  },
                  {
                    required: !!stateIsSuperAdmin,
                    message: "Bitte Email-Adresse angeben!",
                  },
                ]}
                wrapperCol={{ xs: 12 }}
                className="labeledFormItem"
              >
                <Input size="large" disabled={type !== "add"} />
              </Form.Item>
              <Row gutter={{ xs: 16, lg: 32 }}>
                <Col xs={12} lg={6}>
                  <Form.Item
                    className="labeledFormItem"
                    label="Nachname *"
                    name="lastName"
                    required={false}
                    rules={[
                      {
                        required: !!stateIsSuperAdmin,
                        message: "Bitte Nachname angeben!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="quickSearchInput"
                      disabled={!stateIsSuperAdmin}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={6}>
                  <Form.Item
                    className="labeledFormItem"
                    label="Vorname *"
                    name="firstName"
                    required={false}
                    rules={[
                      {
                        required: !!stateIsSuperAdmin,
                        message: "Bitte Vorname angeben!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="quickSearchInput"
                      disabled={!stateIsSuperAdmin}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, lg: 32 }}>
                <Col xs={12} lg={6}>
                  <Form.Item
                    className="labeledFormItem"
                    label="Anrede"
                    name="salutation"
                    required={false}
                  >
                    <Select
                      name="salutation"
                      size="large"
                      disabled={!stateIsSuperAdmin}
                      suffixIcon={<Arrow />}
                    >
                      <Option value="">Ohne Anrede</Option>
                      <Option value="Mrs">Frau</Option>
                      <Option value="Mr">Herr</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={12} lg={6}>
                  <Form.Item
                    name="phone"
                    className="labeledFormItem"
                    label="Telefonnummer *"
                    required={false}
                    rules={[
                      {
                        required: !!stateIsSuperAdmin,
                        message: "Bitte Telefonnummer angeben!",
                      },
                    ]}
                  >
                    <Input size="large" disabled={!stateIsSuperAdmin} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 16, lg: 32 }}>
                <Col xs={12} lg={6}>
                  <Form.Item
                    label="Standardsortierung der Favoritenlisten"
                    className="labeledFormItem"
                    name="favouriteListDefaultSorting"
                    rules={[
                      {
                        type: "enum",
                        enum: [
                          "name_desc",
                          "name_asc",
                          "updated_desc",
                          "updated_asc",
                        ],
                        message: "Bitte eine gültige Sortierung wählen.",
                      },
                    ]}
                  >
                    <Select
                      name="favouriteListDefaultSorting"
                      size="large"
                      suffixIcon={<Arrow />}
                      placeholder="Sortierung wählen..."
                      options={[
                        { label: "Name (A bis Z)", value: "name_asc" },
                        { label: "Name (Z bis A)", value: "name_desc" },
                        {
                          label: "Änderungsdatum (Alte zuerst)",
                          value: "updated_asc",
                        },
                        {
                          label: "Änderungsdatum (Neue zuerst)",
                          value: "updated_desc",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 16, lg: 32 }}>
                <Col xs={12} lg={6}>
                  <Form.Item
                    hidden={
                      !stateIsSuperAdmin ||
                      (stateIsSuperAdmin && superAdminId === uuid)
                    }
                    name="isSuperAdmin"
                    valuePropName="checked"
                    className="labeledFormItem"
                  >
                    <Checkbox>Darf Nutzer bearbeiten</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 16, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    name="deliveryDates"
                    label="Liefertage im Wochenplaner"
                    className="labeledFormItem"
                    tooltip={
                      <span>
                        In der Wochenplaner Tabelle werden nur die ausgewählten
                        Liefertage als Spalten angezeigt. Die Auswahl der grau
                        hinterlegten Felder wurde vom Hauptaccount (Super-Admin)
                        deaktiviert.
                      </span>
                    }
                  >
                    <Checkbox.Group
                      options={customerDeliveryDateOptions}
                      className="companyUserProfileForm__delivery-dates"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 16, lg: 32 }}>
                <Col span={12}>
                  <Form.Item
                    name="showPortalTips"
                    valuePropName="checked"
                    className="labeledFormItem"
                  >
                    <Checkbox>
                      Tipps der Woche anzeigen?
                      <Tooltip title="Legt fest, ob nach dem Login der Tipp der Woche angezeigt wird.">
                        <QuestionCircleOutlined className="ml-xs" />
                      </Tooltip>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 16, lg: 32 }} justify="end">
                <Col
                  xs={12}
                  lg={6}
                  className="flex items-end justify-center lg-justify-end"
                >
                  <div className="flex justify-between lg-justify-end items-center width-full mb-sm">
                    <Link
                      to={
                        stateIsSuperAdmin
                          ? routePathNames.userProfilesAdministration
                          : "/"
                      }
                      className="mr-m button buttonText"
                    >
                      Abbrechen
                    </Link>
                    <button type="submit" className="button buttonPrimary">
                      {type === "edit"
                        ? "Änderungen speichern"
                        : "Nutzer anlegen"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Teaser>
        </Row>
      </div>
      <Row>
        {type === "edit" && !isUnconfirmedUser && (
          <Button
            className="button buttonPrimary buttonPrimary--inverted mt-l mb-l"
            onClick={resetPassword}
            loading={isResettingPassword}
          >
            {superAdminId !== uuid ? "Passwort senden" : "Passwort ändern"}
          </Button>
        )}
        {type === "edit" && stateIsSuperAdmin && isUnconfirmedUser && (
          <Button
            className="button buttonPrimary buttonPrimary--inverted mt-l mb-l"
            onClick={resendRegisterConfirmation}
            loading={isResendingConfirmation}
          >
            Registrierungsbestätigung erneut versenden
          </Button>
        )}
      </Row>
    </>
  );
}
export default UserProfileForm;
