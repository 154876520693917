import React from "react";

import clsx from "clsx";
import { Button } from "antd";

import QuickOrderProductTile from "../../../../pages/QuickOrder/QuickOrderItems/QuickOrderItem/QuickOrderProductTile";
import QuickOrderProductPrices from "../../../../pages/QuickOrder/QuickOrderItems/QuickOrderItem/QuickOrderProductPrices";
import QuickOrderProductAlternatives from "../../../../pages/QuickOrder/QuickOrderItems/QuickOrderItem/QuickOrderProductAlternatives";
import StandingOrderDetails from "./StandingOrderDetails";
import { ReactComponent as DeleteIcon } from "../../../../../static/svg/delete.svg";
import { StandingOrderRequestStateItem } from "../../../../../types/standingOrder";

interface StandingOrderRequestItemProps {
  item: StandingOrderRequestStateItem;
  items: StandingOrderRequestStateItem[];
  deliveryDate: string;
  setItems?: (items: StandingOrderRequestStateItem[]) => void;
  className?: string;
}

const StandingOrderRequestItem: React.FC<StandingOrderRequestItemProps> = (
  props: StandingOrderRequestItemProps
) => {
  const { item = null, items, deliveryDate, setItems, className } = props;

  const deleteItemFromState = () => {
    setItems(
      items.filter(
        (stateItem: StandingOrderRequestStateItem) =>
          `${stateItem?.sku}${stateItem?.weekdayShipmentDate}` !==
          `${item?.sku}${item?.weekdayShipmentDate}`
      )
    );
  };

  if (!item?.product || !item?.product?.sku) {
    return null;
  }

  return (
    <div className={clsx("standing-order-request-item", className)}>
      <QuickOrderProductTile
        deliveryDate={deliveryDate}
        productData={item.product}
      />

      <QuickOrderProductAlternatives
        deliveryDate={deliveryDate}
        productData={item.product}
      />

      <StandingOrderDetails item={item} />

      <QuickOrderProductPrices
        deliveryDate={deliveryDate}
        productData={item.product}
      />

      <div className="standing-order-request-item__info">
        <Button
          type="text"
          icon={<DeleteIcon />}
          onClick={deleteItemFromState}
        />
      </div>
    </div>
  );
};

export default StandingOrderRequestItem;
