import axiosWithAuth from "../axiosWithAuth";
import { customerEndpoint } from "../endpoints";
import { CompanyUserProfile } from "../../types/companyUserProfile";

/**
 * POST / create company User at spryker
 * return new user data
 * @param userData {Object}
 * @return {Promise<AxiosResponse>}
 */

const postCreateCompanyUser = async (userData: CompanyUserProfile) => {
  const {
    firstName,
    lastName,
    phone,
    email,
    isSuperAdmin,
    salutation,
    showPortalTips,
  } = userData;

  const requestBody = {
    data: {
      type: "customers",
      attributes: {
        firstName,
        lastName,
        phone,
        email,
        isSuperAdmin,
        salutation: salutation === "" ? null : salutation,
        showPortalTips,
      },
    },
  };

  return axiosWithAuth()
    .post(customerEndpoint, requestBody)
    .then((response) => {
      if (response?.status !== 201) {
        return Promise.reject(response);
      }

      return response;
    });
};

export default postCreateCompanyUser;
