import React from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { Empty } from "antd";

import StandingOrderRequestItem from "./StandingOrderRequestItem";
import { RootState } from "../../../../types/rootState";
import { StandingOrderRequestStateItem } from "../../../../types/standingOrder";

interface StandingOrderRequestItemsProps {
  items: StandingOrderRequestStateItem[];
  setItems?: (items: StandingOrderRequestStateItem[]) => void;
  className?: string;
}

const StandingOrderRequestItems: React.FC<StandingOrderRequestItemsProps> = (
  props: StandingOrderRequestItemsProps
) => {
  const { items = [], setItems, className } = props;

  const { deliveryDate } = useSelector(
    (state: RootState) => state.currentCartMetaData
  );

  if (!Array.isArray(items)) {
    return null;
  }

  return (
    <div className={clsx("standing-order-request-items", className)}>
      {!!items.length &&
        items.map((item: StandingOrderRequestStateItem) => (
          <StandingOrderRequestItem
            key={`standing-order-request-item__${item?.sku}__${item?.weekdayShipmentDate}`}
            item={item}
            items={items}
            deliveryDate={deliveryDate}
            setItems={setItems}
          />
        ))}

      {!items.length && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <>
              <div>
                Bitte nutzen Sie die Eingabemaske oben, um eine
                Dauerauftrags-Anforderung zu erstellen.
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

export default StandingOrderRequestItems;
