export type AttributeName =
  | "articlePoster"
  | "articleStb"
  | "descriptionFruitVegetable"
  | "varietyFeature"
  | "qualityText"
  | "brandText"
  | "amount"
  | "netWeight"
  | "ingredients"
  | "conventionalAvailable"
  | "conventionalRange"
  | "bnnAroma"
  | "bnnId"
  | "alcohol"
  | "drinkTemperature"
  | "temperatureMax"
  | "temperatureMin"
  | "traditional"
  | "sulphur"
  | "acidity"
  | "residualSugar"
  | "grapeVariety"
  | "producer"
  | "landId"
  | "histamine"
  | "champagne"
  | "wineTasteLong"
  | "wineTasteShort"
  | "geographic"
  | "decantTime"
  | "wineTaste"
  | "animalFood"
  | "teaOfferId"
  | "pbm"
  | "animalTypeMilk"
  | "rawMilk"
  | "homogenized"
  | "temperature"
  | "temperingFinal"
  | "temperingId"
  | "cheeseMilk"
  | "saltType"
  | "crust"
  | "ripeningTime"
  | "ripeningDays"
  | "ripening"
  | "rennetCheese"
  | "cheeseGroup"
  | "fat"
  | "fatContent"
  | "fatStage"
  | "animalType"
  | "remedy"
  | "unitQuantity"
  | "package"
  | "bio"
  | "bioIcon"
  | "bioFairIcon"
  | "bioBasicIcon"
  | "bioId"
  | "bioControlId"
  | "bioConform"
  | "quality"
  | "qualityNorm"
  | "origin"
  | "originLong"
  | "originCountry"
  | "brand"
  | "caliber"
  | "brix"
  | "brixDaily"
  | "brixDaily_HL"
  | "brixDaily_LL"
  | "taste"
  | "enduserPackage"
  | "empties"
  | "emptiesNr"
  | "info_HL"
  | "info_LL"
  | "coCompensatedIcon"
  | "coCompensated"
  | "seedProofIcon"
  | "shoppingArguments"
  | "salesArguments"
  | "advert_HL"
  | "advert_LL"
  | "advertLink_HL"
  | "advertLink_LL"
  | "basePriceUnit"
  | "basePriceFactor"
  | "cultivationInfo"
  | "packageFruits"
  | "weighingArticle"
  | "eanCode"
  | "bestBefore"
  | "gv"
  | "vatGroup"
  | "recommendedRetailPriceWithVat"
  | "mediaVideo"
  | "mediaPdf"
  | "transportTypeIcon"
  | "transportType"
  | "isDisplayText"
  | "isDisplayArticles"
  | "displayText"
  | "isNewArticle"
  | "deprecatedSku"
  | "deprecatedReason_HL"
  | "deprecatedReason_LL"
  | "cultivationMethod"
  | "recommendedRetailPrice"
  | "productGroup_HL"
  | "productGroup_LL"
  | "isPreOrderArticle_HL"
  | "isPreOrderArticle_LL"
  | "preOrderDeliveryDate_HL"
  | "preOrderDeliveryDate_LL"
  | "preOrderOrderDate_HL"
  | "preOrderOrderDate_LL"
  | "preOrderNote_HL"
  | "preOrderNote_LL"
  | "weekplannerGroupHeadline"
  | "articleComposition"
  | "frozen"
  | "cosmeticsInci"
  | "cosmeticsIngredients"
  | "tasteDescriptionShort"
  | "productDescriptionShort"
  | "meatExcludingNps"
  | "vpeWidth"
  | "vpeHeight"
  | "vpeDepth"
  | "gtin"
  | "sanType"
  | "priceUnitText"
  | "lastOrderDate_HL"
  | "lastOrderDate_LL"
  | "pfandpflichtId"
  | "drainWeight"
  | "vpeType"
  | "isDepositVpe"
  | "vpeQuantity"
  | "ingredientsLegend";

const productAttributes: Record<AttributeName, string> = {
  articlePoster: "artikel_mit_poster",
  articleStb: "artikel_stb",
  descriptionFruitVegetable: "artikel_beschreibung_og",
  qualityText: "qualitaeten_name",
  brandText: "herstellermarken_text",
  amount: "artikel_mengeinverpackungseinheit",
  netWeight: "artikel_nettogewicht",
  ingredients: "zut_zutatenverzeichnis",
  conventionalAvailable: "zut_konventionelle_zutaten_vorhanden",
  conventionalRange: "zut_konventionelle_zutaten_auswahl",
  bnnAroma: "zut_bnn_aromenkategorie_vorhanden",
  bnnId: "zut_bnn_aromenkategorie_id",
  alcohol: "wgsa_alkohol",
  drinkTemperature: "weintrinktemperatur",
  temperatureMax: "wein_trinktemperatur_bis",
  temperatureMin: "wein_trinktemperatur_von",
  traditional: "wein_traditionelle_qualitaetsbezeichnungen",
  sulphur: "wein_schwefelzusatzfrei",
  acidity: "wein_saeuregehalt_orientierungswert",
  residualSugar: "wein_restzucker_orientierungswert_id",
  grapeVariety: "wein_rebsorten",
  producer: "wein_produzent_oder_hersteller_regalschild",
  landId: "wein_land_id",
  histamine: "wein_histamingehalt",
  champagne: "wein_geschmack_schaumwein_id",
  wineTasteLong: "wein_geschmack_lang",
  wineTasteShort: "wein_geschmack_kurz",
  geographic: "wein_geographische_angabe_id",
  decantTime: "wein_dekantierzeit_id",
  wineTaste: "wein_geschmack_id",
  animalFood: "tiernahrung_tierart_futtermittel",
  teaOfferId: "tee_angebotsform_id",
  pbm: "pbm_markenname",
  animalTypeMilk: "milch_tierart_milch",
  rawMilk: "milch_rohmilch",
  homogenized: "milch_homogenisierung_id",
  temperature: "log_temperatur_gefuehrt",
  temperingFinal: "kaese_waermebehandlung_endprodukt",
  temperingId: "kaese_waermebehandlung_kaese_id",
  cheeseMilk: "kaese_tierart_milch",
  saltType: "kaese_salzart",
  crust: "kaese_rinde_verzehr_id",
  ripeningTime: "kaese_reifezeit",
  ripeningDays: "kaese_reifezeit_in_tagen",
  ripening: "kaese_reifung",
  rennetCheese: "kaese_labart",
  cheeseGroup: "kaese_kaesegruppe_de_id",
  fat: "kaese_fett_i_tr",
  fatContent: "kaese_fettgehalt_absolut",
  fatStage: "kaese_fettstufe_de_id",
  animalType: "fleisch_tierart",
  remedy: "arznei_sachkundenachweis",
  unitQuantity: "artikel_inhaltbasiseinheit",
  package: "artikel_verpackungstext",
  bio: "artikel_bioladenprodukt",
  bioIcon: "artikel_bioladen_logo",
  bioFairIcon: "artikel_bioladen_fair_logo",
  bioBasicIcon: "artikel_bioladen_basissortiment_logo",
  bioId: "artikel_bio_id",
  bioControlId: "bio_kontrollstelle_id",
  bioConform: "bio_srl_konform",
  quality: "qualitaeten_bezeichnung",
  qualityNorm: "vermarktungsnorm",
  origin: "artikel_ursprungslandregion",
  originLong: "landregion_name",
  originCountry: "artikel_herkunftsland",
  brand: "herstellermarken_preislistentextfuerausgabe",
  caliber: "artikel_kaliber",
  brix: "artikel_brixwertog",
  brixDaily: "brixaktuell",
  brixDaily_HL: "brixaktuell_HL",
  brixDaily_LL: "brixaktuell_LL",
  taste: "artikelpass_geschmacksbeschreibungkurz",
  enduserPackage: "artikel_endverbraucherverpackung_text",
  empties: "artikel_leergutbeschreibung",
  emptiesNr: "artikel_leergutnr_",
  info_HL: "var_produkt_info_HL",
  info_LL: "var_produkt_info_LL",
  seedProofIcon: "sorteneigenschaf_logo",
  varietyFeature: "sorteneigenschaf",
  coCompensatedIcon: "artikel_cokompensiert_logo",
  coCompensated: "artikel_cokompensiert",
  shoppingArguments: "shop_einkauf_argumente",
  salesArguments: "shop_verkauf_argumente",
  basePriceUnit: "artikel_grundpreiseinheit",
  basePriceFactor: "artikel_grundpreisfaktor",
  cultivationInfo: "anbauinfoog",
  packageFruits: "verpackungog",
  weighingArticle: "artikel_wiegeartikel",
  eanCode: "artikel_eancode",
  bestBefore: "artikel_restlaufzeitmhd",
  gv: "artikel_gv",
  vatGroup: "mwst_buchungsmatrixeinr__mwst_",
  recommendedRetailPriceWithVat: "artikel_uvppreiseinheitinkl_mwst_",
  mediaVideo: "video",
  mediaPdf: "artikel_pdf",
  transportTypeIcon: "transportinfo_logo",
  transportType: "transportinfoog",
  advert_HL: "artikel_aktionstext_HL",
  advert_LL: "artikel_aktionstext_LL",
  advertLink_HL: "artikel_aktionslink_HL",
  advertLink_LL: "artikel_aktionslink_LL",
  productGroup_HL: "artikel_collection_area_HL",
  productGroup_LL: "artikel_collection_area_LL",
  isDisplayArticles: "artikel_displaydaten",
  isDisplayText: "artikel_displayinhalt_text",
  displayText: "artikel_display_text",
  isNewArticle: "artikel_neuprodukt",
  deprecatedSku: "artikel_vorgaenger_artikelnr",
  deprecatedReason_HL: "artikel_vorgaenger_auslistungsgrund_HL",
  deprecatedReason_LL: "artikel_vorgaenger_auslistungsgrund_LL",
  cultivationMethod: "anbauinfoog",
  recommendedRetailPrice: "artikel_uvp",
  isPreOrderArticle_HL: "vorbestellartikel_HL",
  isPreOrderArticle_LL: "vorbestellartikel_LL",
  preOrderDeliveryDate_HL: "artikel_auslieferzeitpunkt_HL",
  preOrderDeliveryDate_LL: "artikel_auslieferzeitpunkt_LL",
  preOrderOrderDate_HL: "artikel_bestellzeitpunkt_HL",
  preOrderOrderDate_LL: "artikel_bestellzeitpunkt_LL",
  preOrderNote_HL: "artikel_bestellhinweis_HL",
  preOrderNote_LL: "artikel_bestellhinweis_LL",
  weekplannerGroupHeadline: "artikel_wochenplaner_ueberschrift",
  articleComposition: "zusammensetzungdesartikels",
  frozen: "tk",
  cosmeticsInci: "kosmetik_inci",
  cosmeticsIngredients: "kosmetik_inhaltsstoffe_kosmetik",
  tasteDescriptionShort: "weiling_geschmacksbeschreibung_schild_kurz",
  productDescriptionShort: "markinf_produktbeschreibung_kurz",
  meatExcludingNps: "fleisch_ohne_nps",
  vpeWidth: "artikel_vpe_breite",
  vpeHeight: "artikel_vpe_hoehe",
  vpeDepth: "artikel_vpe_tiefe",
  gtin: "artikel_gtin",
  sanType: "artikel_santyp",
  priceUnitText: "artikel_preiseinheit_text",
  lastOrderDate_HL: "last_order_date_hl",
  lastOrderDate_LL: "last_order_date_ll",
  pfandpflichtId: "pack_pfandpflicht_artikel_de_id",
  drainWeight: "artikel_abtropfgewicht",
  vpeType: "artikel_vpe_verpackungsart",
  isDepositVpe: "artikel_pfandverpackung",
  vpeQuantity: "artikel_vpe_qty",
  ingredientsLegend: "zut_zutatenlegende",
};

Object.freeze(productAttributes);

export { productAttributes };
