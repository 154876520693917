import { useCallback } from "react";

import { debounce } from "debounce";
import { CancelTokenSource } from "axios";

import useUpdateCartItemEmployeeDiscount from "./useUpdateCartItemEmployeeDiscount";
import requestCatchHandler from "../api/requestCatchHandler";
import { delays } from "../appConfig";

interface Props {
  deliveryDate: string;
  cartId: string;
  sku: string;
  setIsLoading?: () => void;
  resolve?: (response: any) => any;
  reject?: (error: any) => any;
  cancelTokenSource: CancelTokenSource;
}

const useDebounceUpdateItemEmployeeDiscount = ({
  deliveryDate,
  cartId,
  sku,
  setIsLoading = () => true,
  resolve = (args) => Promise.resolve(args),
  reject = (error) => requestCatchHandler(error),
  cancelTokenSource,
}: Props) => {
  const debounceTime = delays.updateCartItemQuantity;
  const updateCartItemEmployeeDiscount = useUpdateCartItemEmployeeDiscount();

  // memoize debounced functions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateItemAmount = useCallback(
    debounce((value: number) => {
      setIsLoading();

      updateCartItemEmployeeDiscount({
        deliveryDate,
        cartId,
        sku,
        employeeDiscount: value,
        cancelTokenSource,
      })
        .then((response: any) => {
          return resolve(response);
        })
        .catch((error: any) => reject(error));
    }, debounceTime),
    [updateCartItemEmployeeDiscount]
  );

  return (value: number) => {
    updateItemAmount(value);
  };
};

export default useDebounceUpdateItemEmployeeDiscount;
