import React, { useMemo } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import Price from "../price/Price";
import getCheapestPrice from "./getCheapestPrice";
import { ProductData } from "../../types/productData";
import getDeliveryDateBasedAttributes from "./getDeliveryDateBasedAttributes";
import getProductBasePrice from "../../utils/getProductBasePrice";
import getProductUVP from "../../utils/getProductUVP";
import isProductFruitOrVegetable from "../../utils/isProductFruitOrVegetable";
import { RootState } from "../../types/rootState";

interface Props {
  deliveryDate: string;
  prices: ProductData["prices"];
  quantity?: number;
  productUnitMeasurement?: string;
  basePriceQuantity: string;
  basePriceUnit?: string;
  basePriceFactor?: string;
  className?: string;
  weighingArticle?: string;
  showProductUVP?: boolean;
  sku?: string;
}

/**
 * show the product price with different options
 * @param deliveryDate
 * @param prices
 * @param quantity
 * @param productUnitMeasurement
 * @param basePriceQuantity
 * @param basePriceUnit
 * @param basePriceFactor
 * @param className
 * @param weighingArticle
 * @param sku
 * @param showProductUVP
 * @constructor
 */
const ProductPrices: React.FC<Props> = ({
  deliveryDate,
  prices,
  quantity,
  basePriceQuantity = "1",
  basePriceUnit,
  basePriceFactor,
  className,
  productUnitMeasurement,
  weighingArticle = "0",
  showProductUVP = false,
  sku = "",
}: Props) => {
  const { price, strikePrice, volumePrices, rrPrice } =
    getDeliveryDateBasedAttributes({
      deliveryDate,
      prices,
    });

  const hidePrices = useSelector(
    (state: RootState) => state?.appSettings?.hidePrices || false
  );

  const hasBasePriceUnit = useMemo(
    () => (basePriceUnit ? !!String(basePriceUnit).trim() : false),
    [basePriceUnit]
  );

  // test explicitly for "null" because a price could be 0 and this is valid
  if (!prices || !price === null) {
    return null;
  }

  const hasStrikePrice = strikePrice > 0;

  // determine the cheapest price based on quantity and volumes
  const cheapestPrice = getCheapestPrice(price, volumePrices, quantity);

  const basePriceQuantityNumber: number = parseFloat(basePriceQuantity);

  const productBasePrice = getProductBasePrice(
    cheapestPrice,
    parseFloat(basePriceQuantity),
    parseFloat(basePriceFactor),
    basePriceUnit,
    productUnitMeasurement,
    weighingArticle
  );

  const isFruitOrVegetableProduct = isProductFruitOrVegetable(sku);

  const productUVP = getProductUVP(
    rrPrice,
    basePriceQuantity,
    weighingArticle === "1"
  );

  const productStrikePrice =
    weighingArticle === "1"
      ? strikePrice
      : strikePrice / basePriceQuantityNumber;

  const productPrice =
    weighingArticle === "1"
      ? cheapestPrice
      : cheapestPrice / basePriceQuantityNumber;

  return (
    <div className={clsx("productPrices", hidePrices && "blurred", className)}>
      {hasStrikePrice && (
        <Price
          price={productStrikePrice}
          className="isStrikeThroughPrice"
          tag="div"
        />
      )}

      <Price
        price={productPrice}
        className={clsx(
          "productPrice ",
          hasStrikePrice && "hasStrikeThroughPrice"
        )}
        tag="div"
        baseUnit={productUnitMeasurement}
      />
      {showProductUVP && !!productUVP && (
        <div className={clsx("product-UVP", className)}>
          <span>
            UVP: <Price price={productUVP} />
          </span>
        </div>
      )}
      {!isFruitOrVegetableProduct && basePriceFactor && hasBasePriceUnit && (
        <div className={clsx("base-price", className)}>
          {" "}
          <Price price={productBasePrice} /> {" / "} {basePriceUnit}
        </div>
      )}
    </div>
  );
};

export default ProductPrices;
