import React, { MouseEventHandler } from "react";

import { Radio } from "antd";
import clsx from "clsx";

import { BusinessUnit } from "../../../../types/businessUnits";

interface BusinessUnitModalItemProps {
  businessUnit: BusinessUnit;
  selected: boolean;
  value: string;
  onItemClick?: (value: string) => void;
}

const BusinessUnitModalItem: React.FC<BusinessUnitModalItemProps> = (
  props: BusinessUnitModalItemProps
) => {
  const { businessUnit, selected, value, onItemClick } = props;

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (typeof onItemClick === "function") {
      onItemClick(value);
    }
  };

  return (
    <button
      type="button"
      className={clsx(
        "business-unit-grid",
        selected && "business-unit-grid-selected"
      )}
      onClick={handleButtonClick}
    >
      <Radio value={value} />
      <div className="business-unit-tooltip-key">
        <div className="business-unit-tooltip-key-key">Nr.</div>
        <div className="business-unit-tooltip-key-value">
          {businessUnit.companyBusinessUnitKey}
        </div>
      </div>
      <div className="business-unit-tooltip-address">
        <div className="business-unit-tooltip-address-key">Adresse</div>
        <div className="business-unit-tooltip-address-address">
          {businessUnit?.companyName ? `${businessUnit.companyName} | ` : ""}
          {businessUnit?.address1 ? `${businessUnit.address1} | ` : ""}
          {businessUnit?.city ? `${businessUnit.city}` : ""}
        </div>
      </div>
    </button>
  );
};

export default BusinessUnitModalItem;
