import moment from "moment";

const getGreeting = (): string => {
  const currentHour = moment().hour();

  let greetingText = "";

  if (currentHour >= 6 && currentHour < 12) {
    greetingText = "Guten Morgen";
  } else if (currentHour >= 12 && currentHour < 18) {
    greetingText = "Guten Tag";
  } else if (currentHour >= 18 && currentHour < 24) {
    greetingText = "Guten Abend";
  } else {
    greetingText = "Gute Nacht";
  }

  return greetingText;
};

export { getGreeting };
