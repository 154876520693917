import React, { useMemo } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import moment from "moment/moment";

import getProductUVP from "../../../utils/getProductUVP";
import CartCheckout from "../../cart/CartCheckout";
import Price from "../../price/Price";
import { fullDateFormat } from "../../../utils/dateFormats";
import { PrintViewTableDataProps } from "../../../types/printViewTableData";
import { RootState } from "../../../types/rootState";
import { ReactComponent as UserIcon } from "../../../static/svg/account.svg";
import { ReactComponent as TruckIcon } from "../../../static/svg/neuprodukt_lieferant.svg";
import {
  PrintViewBanner,
  PrintViewGreeting,
  PrintViewSupport,
} from "../../atoms";
import PrintViewCartNote from "../../atoms/PrintViewCartNote";
import { PrintViewFavouriteListTableDataProps } from "../../../types/printViewFavouriteListTableData";

interface PrintViewProps {
  cartNote: string;
  printLayout: "cart" | "order" | "favouriteList";
  className?: string;
  printViewTableData?: PrintViewTableDataProps[];
  printViewFavouriteListTableData?: PrintViewFavouriteListTableDataProps[];
  favouriteListName?: string;
  checkout?: JSX.Element;
  orderDate?: string;
  deliveryDate?: string;
}

const PrintView: React.FC<PrintViewProps> = (props: PrintViewProps) => {
  const {
    className = "",
    printLayout = "",
    deliveryDate = "",
    orderDate = "",
    cartNote = "",
    favouriteListName = "",
    checkout = null,
    printViewTableData = [],
    printViewFavouriteListTableData = [],
  } = props;

  const { businessUnit } = useSelector(
    (state: RootState) => state?.userData || {}
  );

  const itemsSumPrice = useMemo(
    () =>
      printViewTableData
        ?.map((item: any) => item.sumPrice || 0)
        ?.reduce((sum: number, cur: number) => sum + cur, 0),
    [printViewTableData]
  );

  const customerInfo = (
    <div className="print-view__content__order__customer-info">
      <span className="print-view__content__order__customer-info__address">
        <UserIcon />
        &nbsp;Lieferadresse
      </span>
      <br />
      <span>Kundennummer: {businessUnit?.companyBusinessUnitKey}</span>
      <br />
      <br />
      <span>{businessUnit?.companyName}</span>

      <br />
      <span>{businessUnit?.address1}</span>
      <br />
      <span>
        {businessUnit?.zipCode} {businessUnit?.city}
      </span>
    </div>
  );

  const cartOrderItemTable = (
    <div className="print-view__content__product-table">
      <table>
        <thead>
          <tr>
            <th>Nr</th>
            <th>Artikelbezeichnung</th>
            <th>Anzahl</th>
            <th>VPE</th>
            <th>UVP</th>
            <th>Einzelpreis</th>
            <th>Gesamtpreis</th>
          </tr>
        </thead>
        <tbody>
          {printViewTableData?.map((item) => {
            const productUVP = getProductUVP(
              item?.rrPrice || 0,
              item?.itemBaseUnit || 1,
              item?.weighingArticle === "1"
            );

            return (
              <tr key={`print-${item?.sku}`}>
                <td>{item?.sku}</td>
                <td>{item?.description}</td>
                <td>{item?.quantity}</td>
                <td>
                  {item.itemBaseUnit} x {item?.itemVpe}
                </td>
                <td>{!productUVP ? "-" : <Price price={productUVP} />}</td>
                <td>
                  <Price price={item?.unitPrice} />
                </td>
                <td>
                  <Price price={item?.sumPrice} />
                </td>
              </tr>
            );
          })}
          <tr key="print-sumPrice">
            <td colSpan={6}>Gesamtsumme</td>
            <td>
              <Price price={itemsSumPrice} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div className={clsx("print-view", "show-print", className)}>
      <PrintViewBanner />
      <div className="print-view__content">
        {printLayout === "cart" && (
          <>
            <h3>
              Ihre Bestellung zum {moment(deliveryDate).format(fullDateFormat)}{" "}
              vom Warenkorb
            </h3>
            <div className="print-view__content__order">
              <div className="print-view__content__order__order-info">
                <div className="print-view__content__order__order-info__dates">
                  <span
                    className={clsx(
                      printLayout === "cart" && "cart-delivery-date"
                    )}
                  >
                    <TruckIcon />
                    <span>
                      Lieferdatum
                      <br />
                      {moment(deliveryDate).format(fullDateFormat)}
                    </span>
                  </span>
                </div>
                {checkout}
              </div>
              {customerInfo}
            </div>
            {cartOrderItemTable}
            <PrintViewCartNote
              cartNote={cartNote}
              hasNotePersonalSpace={false}
            />

            <PrintViewSupport isFavouriteList={false} />
            <PrintViewGreeting />
          </>
        )}

        {printLayout === "order" && (
          <>
            <h3>
              Ihre Bestellung zum {moment(deliveryDate).format(fullDateFormat)}
            </h3>
            <div className="print-view__content__order">
              <div className="print-view__content__order__order-info">
                <div className="print-view__content__order__order-info__dates">
                  <span>
                    <TruckIcon />
                    <span>
                      Lieferdatum
                      <br />
                      {moment(deliveryDate).format(fullDateFormat)}
                    </span>
                  </span>
                  <span>
                    <TruckIcon />
                    <span>
                      Bestelldatum
                      <br />
                      {moment(orderDate).format(fullDateFormat)}
                    </span>
                  </span>
                </div>
                <CartCheckout
                  totalSelectedItems={printViewTableData?.length || 0}
                  selectedItemsTotal={itemsSumPrice}
                  isAlwaysVisible
                  confirmationPlacement="top"
                  disabled
                />
              </div>
              {customerInfo}
            </div>
            {cartOrderItemTable}
            <PrintViewCartNote
              cartNote={cartNote}
              hasNotePersonalSpace={false}
            />
            <PrintViewGreeting />
            <PrintViewSupport isFavouriteList={false} />
          </>
        )}

        {printLayout === "favouriteList" && (
          <>
            <h3>{`Favoritenliste "${favouriteListName}"`}</h3>
            <PrintViewCartNote cartNote={cartNote} hasNotePersonalSpace />
            <div className="print-view__content__product-table-favorite-list">
              <table>
                <thead>
                  <tr>
                    <th>Nr</th>
                    <th>Artikelbezeichnung</th>
                    <th>Marke</th>
                    <th>VPE</th>
                    <th>UVP</th>
                    <th>Einzelpreis</th>
                  </tr>
                </thead>
                <tbody>
                  {printViewFavouriteListTableData?.map((item) => {
                    const productUVP = getProductUVP(
                      item?.rrPrice,
                      item?.itemBaseUnit,
                      item?.weighingArticle === "1"
                    );

                    return (
                      <tr key={`print-${item?.sku}`}>
                        <td>{item?.sku}</td>
                        <td>{item?.description}</td>
                        <td>{item?.brand}</td>
                        <td>
                          {item.itemBaseUnit} x {item?.itemVpe}
                        </td>
                        <td>
                          {!productUVP ? "-" : <Price price={productUVP} />}
                        </td>
                        <td>
                          <Price price={item?.unitPrice} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <PrintViewSupport isFavouriteList />
          </>
        )}
      </div>
      <PrintViewBanner />
    </div>
  );
};

export default PrintView;
