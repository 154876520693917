import React from "react";

import clsx from "clsx";
import { Carousel, Col, Row } from "antd";
import { useSelector } from "react-redux";

import useGetWebshopOffers from "../../../hooks/useGetWebshopOffers";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import Picture from "../../picture/Picture";
import { ContentBlock, PictureTile, QuickLink } from "../../atoms";
import { ContentfulWebshopOfferEntry } from "../../../types/contentfulWebshopOfferEntry";
import { RootState } from "../../../types/rootState";

interface WebshopOffersWidgetProps {
  className?: string;
}

const WebshopOffersWidget: React.FC<WebshopOffersWidgetProps> = (
  props: WebshopOffersWidgetProps
) => {
  const { className } = props;

  const isSmallScreen = useMedia(
    `(max-width: ${getCssVariable("screen-md-max")})`
  );

  const { stockName } = useSelector((state: RootState) => state?.userData);

  const { isLoading, hasError, entries } = useGetWebshopOffers(8, 0, stockName);

  if (isLoading || !entries?.length || hasError) {
    return <></>;
  }

  return (
    <ContentBlock
      className={clsx("webshop-offers-widget", className)}
      showHeader={false}
    >
      <Row gutter={[20, 10]}>
        {!isSmallScreen && !isLoading && !hasError && !!entries && (
          <>
            {entries.map((offer: ContentfulWebshopOfferEntry, i: number) => (
              <Col span={3} key={`dashboard_offer_${i}`}>
                <PictureTile
                  title={null}
                  picture={
                    <Picture
                      imageScreenSm={`${offer.imageUrl}?fit=fill&f=top&r=10&fm=webp&q=40`}
                      imageScreenXl={`${offer.imageUrl}?fit=fill&f=top&r=10&fm=webp&q=80`}
                    />
                  }
                  link={
                    offer?.callToAction && offer?.link ? (
                      <QuickLink
                        label={offer.callToAction}
                        href={offer.link}
                        linkType="primary--outlined"
                      />
                    ) : null
                  }
                  className="webshop-offers-widget__picture-tile"
                />
              </Col>
            ))}
          </>
        )}

        {isSmallScreen &&
          !isLoading &&
          !hasError &&
          !!entries &&
          entries.length && (
            <Col span={12}>
              <Carousel>
                {entries.map(
                  (offer: ContentfulWebshopOfferEntry, i: number) => (
                    <PictureTile
                      key={`offer_${i}`}
                      title={null}
                      picture={
                        <Picture
                          imageScreenXs={`${offer.imageUrl}?fit=fill&f=top&r=10&fm=webp&q=40`}
                          imageScreenSm={`${offer.imageUrl}?fit=fill&f=top&r=10&fm=webp&q=40`}
                        />
                      }
                      link={
                        offer?.callToAction && offer?.link ? (
                          <QuickLink
                            label={offer.callToAction}
                            href={offer.link}
                            linkType="primary--outlined"
                          />
                        ) : null
                      }
                      className="webshop-offers-widget__picture-tile"
                    />
                  )
                )}
              </Carousel>
            </Col>
          )}
      </Row>
    </ContentBlock>
  );
};

export default WebshopOffersWidget;
