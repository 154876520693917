import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import { AttributeName, productAttributes } from "../../api/productAttributes";

import CartItemTableChildRow from "./CartItem/CartItemTableChildRow";
import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import { ProductData } from "../../types/productData";
import compareProductCollectionGroups from "../../utils/compareProductCollectionGroups";
import sortProductsBySortIndex from "../../utils/sortProductsBySortIndex";
import calculateTotalGroupPrice from "../../utils/calculateTotalGroupPrice";

/**
 * custom hook to build up the CartItemRows
 */
const useCartItemRows = function useCartItemRows(
  isSmallScreen = false,
  cartDiscount = ""
) {
  const deliveryDate = useSelector(getCartDeliveryDateSlice);
  const { cartItems } = useSelector((state: any) => state.currentCart);
  const { stockName } = useSelector((state: any) => state.userData);
  const { dayShort } = useSelector((state: any) => state.currentCartMetaData);

  return useMemo(
    () =>
      cartItems
        .reduce((accumulator: any[], currentCartItem: ProductData) => {
          let currentDeadlineGroup: Record<string, any>;
          let currentProductGroupList: Record<string, any>;

          const currentDeadline = currentCartItem.deadline;

          // find product group from attributes
          const currentProductGroup =
            currentCartItem.attributes[
              productAttributes[`productGroup_${stockName}` as AttributeName]
            ];

          /*
           * find possible matching time groups, if any exists
           * if so, the last is used
           */
          const matchingDeadlineGroup = accumulator.find(
            (cartItemGroup: any) => cartItemGroup.deadline === currentDeadline
          );

          // if no matching group by deadline is available
          currentDeadlineGroup = compareProductCollectionGroups({
            productGroup: matchingDeadlineGroup,
            lookupGroup: currentDeadline,
            groupType: "deadline",
            deliveryDate,
          });

          /*
           * same with product group
           * but here we have to check if a product group exist
           * in currentDeadlineGroup
           */
          const matchingProductGroup = currentDeadlineGroup.children?.find(
            (cartProductGroup: any) =>
              cartProductGroup.productGroup === currentProductGroup
          );

          // ALL other productGroups that are NOT currentProductGroup
          const notMatchingProductGroup = currentDeadlineGroup.children?.filter(
            (cartProductGroup: any) =>
              cartProductGroup.productGroup !== currentProductGroup
          );

          // if no matching group by product group is available
          currentProductGroupList = compareProductCollectionGroups({
            productGroup: matchingProductGroup,
            lookupGroup: currentProductGroup,
            groupType: "productGroup",
          });

          // find current total Price from the cartItem
          const currentTotalPrice = currentCartItem.calculations.sumPrice;

          // calculate accumulated total price in the current product group
          currentProductGroupList = calculateTotalGroupPrice({
            currentProductGroupList,
            currentTotalPrice,
          });

          // Render group sum and toggle icon for small devices
          if (isSmallScreen) {
            currentProductGroupList = {
              ...currentProductGroupList,
              articleElement: (
                <div className="cartItemGroup--small">
                  <span className="cartItemGroupHeading">
                    {currentProductGroupList.productGroup}
                  </span>
                  {currentProductGroupList.total}
                  {currentProductGroupList.interactionIcon}
                </div>
              ),
            };
          }

          // fill with cartItem
          currentProductGroupList = {
            ...currentProductGroupList,
            children: [
              ...currentProductGroupList.children,
              CartItemTableChildRow(
                { ...currentCartItem },
                deliveryDate,
                stockName,
                dayShort,
                isSmallScreen,
                cartDiscount
              ),
            ].sort(sortProductsBySortIndex),
          };

          // fill with productGroupList
          currentDeadlineGroup = {
            ...currentDeadlineGroup,
            children: [
              // put ALL other productGroups here
              ...notMatchingProductGroup,
              currentProductGroupList,
            ],
          };

          const filteredAccumulator = accumulator.filter(
            (groupEntry: any) => groupEntry.key !== currentDeadlineGroup.key
          );

          return [...filteredAccumulator, currentDeadlineGroup];
        }, [])
        .sort((a: any, b: any) => a.deadline - b.deadline),
    [cartItems, stockName, deliveryDate, isSmallScreen, dayShort, cartDiscount]
  );
};

export default useCartItemRows;
