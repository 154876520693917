import React, { useRef, useState } from "react";

import clsx from "clsx";
import { Button, message, Modal, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";

import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import postStandingOrderRequest from "../../../api/standingOrder/postStandingOrderRequest";
import requestCatchHandler from "../../../api/requestCatchHandler";
import StandingOrderRequestModalForm from "./StandingOrderRequestModalForm";
import StandingOrderRequestItems from "./StandingOrderRequestItems";
import { messageData } from "../../../appConfig";
import { RootState } from "../../../types/rootState";
import { ProductData } from "../../../types/productData";
import { StandingOrderRequestStateItem } from "../../../types/standingOrder";
import { ReactComponent as PlusIcon } from "../../../static/svg/plus.svg";

interface StandingOrderRequestModalProps {
  className?: string;
  wrapperClassName?: string;
}

const StandingOrderRequestModal: React.FC<StandingOrderRequestModalProps> = (
  props: StandingOrderRequestModalProps
) => {
  const { className, wrapperClassName } = props;

  const [form] = useForm();

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const { deliveryDate } = useSelector(
    (state: RootState) => state.currentCartMetaData
  );

  const [product, setProduct] = useState<ProductData>(null);
  const [items, setItems] = useState<StandingOrderRequestStateItem[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const requestStandingOrders = () => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );
    setIsRequesting(true);

    postStandingOrderRequest({
      items: items?.map((item: StandingOrderRequestStateItem) => {
        return {
          sku: item?.sku,
          productName: item?.product?.name,
          quantity: item?.quantity,
          weekdayShipmentDate: item?.weekdayShipmentDate,
          interval: item?.interval,
          startDate: item?.startDate,
          endDate: item?.endDate,
        };
      }),
      cancelTokenSource: cancelTokenSource.current,
    })
      .then(() => {
        setIsRequesting(false);
        setIsModalVisible(false);
        setItems([]);
        form.resetFields();
        setIsConfirmModalVisible(true);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsRequesting(false);
          message.error(messageData.error.standingOrderRequest.postRequest);
        }
        requestCatchHandler(error);
      });
  };

  return (
    <div className={clsx("standing-order-request-modal", className)}>
      <Button
        className="buttonPrimary"
        onClick={() => setIsModalVisible(true)}
        icon={<PlusIcon />}
        shape="circle"
      />
      <Modal
        title={<h3>Anforderung Dauerauftrag</h3>}
        wrapClassName={clsx(
          "standing-order-request-modal-wrapper",
          wrapperClassName
        )}
        visible={isModalVisible}
        okText="Anfordern"
        okButtonProps={{ disabled: items?.length < 1, loading: isRequesting }}
        onOk={requestStandingOrders}
        cancelText="Schließen"
        onCancel={() => setIsModalVisible(false)}
        width="95%"
        closable
        keyboard={false}
        forceRender
        maskClosable={false}
      >
        <Spin spinning={isRequesting}>
          <StandingOrderRequestModalForm
            form={form}
            deliveryDate={deliveryDate}
            product={product}
            setProduct={setProduct}
            isSearching={isSearching}
            setIsSearching={setIsSearching}
            items={items}
            setItems={setItems}
          />
          <StandingOrderRequestItems items={items} setItems={setItems} />
        </Spin>
      </Modal>
      <Modal
        title="Anforderung versendet"
        wrapClassName="standing-order-request-confirm-modal-wrapper"
        visible={isConfirmModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Schließen"
        cancelButtonProps={{ className: "ant-btn-primary" }}
        onCancel={() => setIsConfirmModalVisible(false)}
        closable
        keyboard={false}
        maskClosable={false}
      >
        <p>
          Ihre Dauerauftrags-Anforderung wurde versendet und wird nun von Ihrer
          Kundenbetreuung bearbeitet.
        </p>
        <p>
          Sobald der Dauerauftrag angelegt wurde wird er in der
          Dauerauftragsübersicht angezeigt.
        </p>
      </Modal>
    </div>
  );
};

export default StandingOrderRequestModal;
