import React, { useMemo } from "react";

import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import getCssVariable from "../../utils/getCssVariable";
import useIsForeignCompanyBusinessUnit from "../../hooks/useIsForeignCompanyBusinessUnit";
import useMedia from "../../hooks/useMedia";
import DeletedOrChangedCartItemList from "./deletedOrChangedCartItems/DeletedOrChangedCartItemList";
import useCartItemRows from "./useCartItemRows";
import ProductItemTable from "../ProductItemTable/ProductItemTable";
import EmptyListContent from "../EmptyListContent/EmptyListContent";
import MoveCartToDate from "./MoveProductItemsToDate/MoveCartToDate";
import QuickOrderInput from "./quickOrder/QuickOrder";
import { RootState } from "../../types/rootState";

/*
 * columns for table
 */
const largeScreenColumns = [
  {
    title: "",
    dataIndex: "articleElement",
    width: 100,
  },
  {
    title: "",
    dataIndex: "addToListButtons",
    width: 100,
  },
  {
    title: "Anzahl",
    dataIndex: "quantity",
    width: 70,
  },
  {
    title: "VPE",
    dataIndex: "packaging",
    width: 100,
  },
  {
    title: "Einzelpreis",
    dataIndex: "unitPrice",
    width: 120,
  },
  {
    title: "kalkul. UVP",
    dataIndex: "rrp",
    width: 150,
  },
  {
    title: "Gesamtsumme",
    dataIndex: "total",
    width: 150,
  },
  {
    title: "",
    dataIndex: "interactionIcon",
    width: 50,
  },
];

const smallScreenColumns = [
  {
    title: "",
    dataIndex: "articleElement",
    width: 100,
  },
];

const employeeDiscountColumn = {
  title: "Rabatt",
  dataIndex: "discount",
  width: 110,
  align: "center",
};

interface CartItemsListProps {
  cartDiscount?: string;
}

/**
 * table component wrapping cart items
 * expandIcon: normally the icon should be set via the expanded props
 * for the table, unfortunately, the design requires the icon at the exact same
 * spot as the dotted menu, so it cannot be set in an extra table cell
 * @constructor
 */
const CartItemsList: React.FC<CartItemsListProps> = (
  props: CartItemsListProps
) => {
  const { cartDiscount } = props;

  const { isForeignCompanyBusinessUnit } = useIsForeignCompanyBusinessUnit();
  const isSmallScreen = useMedia(
    `(max-width: ${getCssVariable("screen-md-max")})`
  );
  const dispatch = useDispatch();

  // states
  const { selectedCartItems } = useSelector((state: any) => state.currentCart);
  const { isEmployee = false } =
    useSelector((state: RootState) => state?.userData) || {};

  // split the items in groups of timed order limits, memoize them to reduce costs
  const cartItemRows = useCartItemRows(isSmallScreen, cartDiscount);

  // memoize the parent rows
  const expandableRowKeys = useMemo(
    () =>
      cartItemRows
        .map((deadlineRow: any) => {
          const list: string[] = [deadlineRow.key];
          deadlineRow.children?.map((productGroupRow: any) =>
            list.push(productGroupRow.key)
          );
          return list;
        })
        .flat(),
    [cartItemRows]
  );

  const columns: any = useMemo(() => {
    if (!isSmallScreen && isEmployee && isForeignCompanyBusinessUnit) {
      const unitPriceIndex = largeScreenColumns.findIndex(
        (col) => col?.dataIndex === "unitPrice"
      );

      if (unitPriceIndex !== -1) {
        return [
          ...largeScreenColumns.slice(0, unitPriceIndex),
          employeeDiscountColumn,
          ...largeScreenColumns.slice(unitPriceIndex + 1),
        ];
      }
    }

    return isSmallScreen ? smallScreenColumns : largeScreenColumns;
  }, [isEmployee, isForeignCompanyBusinessUnit, isSmallScreen]);

  /**
   * update the redux state based on the updated view
   * @param rows {Record<any, any>[]}
   * @param selected {boolean}
   */
  const updateSelectedSkus = (rows: Record<any, any>[], selected: boolean) => {
    if (rows && Array.isArray(rows)) {
      rows.forEach(({ sku }) => {
        dispatch({
          type: selected
            ? "cart/add-selected-cart-item"
            : "cart/delete-selected-cart-item",
          payload: sku,
        });
      });
    }
  };

  /*
   * locale object of table
   * emptyText: the elements, which will be displayed if the table is empty
   */
  const locale = {
    emptyText: (
      <EmptyListContent
        description="Keine Produkte im Warenkorb vorhanden."
        withRedirectButton
      />
    ),
  };

  return (
    <ProductItemTable
      columns={columns}
      expandableRowKeys={expandableRowKeys}
      itemRowClassName="cartItem"
      locale={locale}
      productItemRows={cartItemRows}
      selectedRowKeys={selectedCartItems}
      updateSelectedRows={updateSelectedSkus}
      childrenBetweenHeaderAndTable={
        <>
          <Row>
            <Col span={12}>
              <DeletedOrChangedCartItemList type="deletedItems" />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DeletedOrChangedCartItemList type="countChangedItems" />
            </Col>
          </Row>
        </>
      }
      rightHeaderComponent={
        <>
          <MoveCartToDate />

          <QuickOrderInput />
        </>
      }
    />
  );
};

export default CartItemsList;
