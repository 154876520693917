import React, { BaseSyntheticEvent, ReactNode, useRef, useState } from "react";

import clsx from "clsx";
import axios, { CancelTokenSource } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "debounce";

import axiosWithAuth from "../../../api/axiosWithAuth";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import requestCatchHandler from "../../../api/requestCatchHandler";
import PercentNumberInput from "../../atoms/PercentNumberInput";
import { cartsEndpoint } from "../../../api/endpoints";
import { delays } from "../../../appConfig";
import { RootState } from "../../../types/rootState";

interface CartEmployeeDiscountProps {
  disabled?: boolean;
  className?: string;
  addonBefore?: ReactNode;
}

const CartEmployeeDiscount: React.FC<CartEmployeeDiscountProps> = (
  props: CartEmployeeDiscountProps
) => {
  const { disabled = false, className, addonBefore } = props;

  const { employeeDiscount = "" } =
    useSelector((state: RootState) => state?.currentCart) || {};

  const dispatch = useDispatch();
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const { id: reduxCartId, etag: currCartETag } = useSelector(
    (state: RootState) => state.currentCartMetaData
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = debounce((e: BaseSyntheticEvent) => {
    const discount = e.target?.value?.replace(",", ".")?.trim() || "";

    setIsLoading(true);

    axiosWithAuth()
      .patch(
        `${cartsEndpoint}/${reduxCartId}`,
        {
          data: {
            type: "carts",
            attributes: {
              employeeDiscount:
                discount !== "" ? Number(discount) * 100 : discount,
            },
          },
        },
        {
          headers: {
            "If-Match": currCartETag,
          },
          cancelToken: cancelTokenSource.current?.token,
        }
      )
      .then((response: any) => {
        setIsLoading(false);

        dispatch({
          type: "cart/set-cart-employee-discount",
          payload: {
            employeeDiscount:
              response?.data?.data?.attributes?.employeeDiscount ?? "",
          },
        });

        dispatch({
          type: "cartMeta/set-new-cart-note",
          payload: {
            etag: response?.headers?.etag,
          },
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
        }
        requestCatchHandler(error);
      });
  }, delays.updateCartItemQuantity);

  return (
    <div className={clsx("cart-employee-discount", className)}>
      <PercentNumberInput
        value={Number.isInteger(employeeDiscount) ? employeeDiscount / 100 : ""}
        disabled={disabled || isLoading}
        onChange={handleChange}
        addonBefore={addonBefore}
      />
    </div>
  );
};

export default CartEmployeeDiscount;
