import React, { BaseSyntheticEvent, useRef, useState } from "react";

import clsx from "clsx";
import axios, { CancelTokenSource } from "axios";
import { useSelector } from "react-redux";

import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import useDebounceUpdateItemEmployeeDiscount from "../../../hooks/useDebounceUpdateItemEmployeeDiscount";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import PercentNumberInput from "../../atoms/PercentNumberInput";
import { RootState } from "../../../types/rootState";

interface CartItemEmployeeDiscountProps {
  value: string | number;
  sku: string;
  deliveryDate: string;
  disabled?: boolean;
  className?: string;
}

const CartItemEmployeeDiscount: React.FC<CartItemEmployeeDiscountProps> = (
  props: CartItemEmployeeDiscountProps
) => {
  const { value, sku, deliveryDate, disabled = false, className } = props;

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const { currentCart } = useSelector((state: RootState) => state);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const changeItemEmployeeDiscount = useDebounceUpdateItemEmployeeDiscount({
    cartId: currentCart.cartItems.cartId,
    deliveryDate,
    sku,
    cancelTokenSource: cancelTokenSource.current,
    setIsLoading: () => {
      setIsLoading(true);
    },
    resolve: () => {
      setIsLoading(false);
    },
    reject: (error) => {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
      }
    },
  });

  const handleChange = (e: BaseSyntheticEvent) => {
    const employeeDiscount = Number(e.target?.value.replace(",", ".")) * 100;
    changeItemEmployeeDiscount(employeeDiscount);
  };

  return (
    <div className={clsx("cart-item-employee-discount", className)}>
      <PercentNumberInput
        value={value}
        disabled={disabled || isLoading}
        onChange={handleChange}
      />
    </div>
  );
};

export default CartItemEmployeeDiscount;
