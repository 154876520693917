import { useMemo } from "react";

import { useSelector } from "react-redux";

import getCssVariable from "../utils/getCssVariable";
import useMedia from "./useMedia";
import { RootState } from "../types/rootState";

const useGetCustomerDisplayName = () => {
  const { firstName, lastName }: { firstName: string; lastName: string } =
    useSelector((state: RootState) => state?.userData);

  const isLargeScreen = useMedia(`(min-width: ${getCssVariable("screen-lg")})`);

  const customerDisplayName = useMemo(() => {
    if (!firstName || !lastName) {
      return null;
    }

    if (!isLargeScreen) {
      return `${firstName?.trim()?.[0]?.toUpperCase()}${lastName
        ?.trim()?.[0]
        ?.toUpperCase()}`;
    }

    return `${firstName} ${lastName}`?.trim();
  }, [firstName, isLargeScreen, lastName]);

  return { customerDisplayName };
};

export default useGetCustomerDisplayName;
