import aggregateProductData from "../../utils/aggregateProductData";
import sumCartItems from "../../utils/sumCartItems";
import getCartItemsTotalValue from "../../utils/getCartItemsTotalValue";
import { ProductData } from "../../types/productData";

const initialState = {
  cartItems: <Record<string, any>[]>[],
  cartItemsTotalQuantity: 0,
  cartItemsTotalValue: 0,
  cartId: "",
  selectedCartItems: <string[]>[],
  cartItemAlternatives: <Record<string, Record<string, any>[]>>{},
};

const currentCartItemsReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // Clears all cart items
    case "cart/clear-cart":
      return initialState;

    // adds new item to cart
    case "cart/item-add": {
      const cartItems = [...state.cartItems, payload];
      const selectedCartItems = [...state.selectedCartItems, payload.sku];
      const cartItemsTotalQuantity = sumCartItems(cartItems);
      const cartItemsTotalValue = getCartItemsTotalValue(cartItems);

      return {
        ...state,
        cartItems,
        selectedCartItems,
        cartItemsTotalQuantity,
        cartItemsTotalValue,
      };
    }

    // deletes item from cart
    case "cart/item-remove": {
      // remove sku from cartItems and selected
      const remainingItems = state.cartItems.filter(
        (cartItem: any) => cartItem.sku !== payload
      );
      const remainingSelected = state.selectedCartItems.filter(
        (itemSku: any) => itemSku !== payload
      );

      const cartItemsTotalQuantity = sumCartItems(remainingItems);
      const cartItemsTotalValue = getCartItemsTotalValue(
        remainingItems as ProductData[]
      );

      return {
        ...state,
        cartItems: remainingItems,
        selectedCartItems: remainingSelected,
        cartItemsTotalQuantity,
        cartItemsTotalValue,
      };
    }

    // Changes an items amount
    case "cart/change-item-quantity": {
      const updatedCartItems = state.cartItems.map((cartItem: any) => {
        if (cartItem.sku === payload.sku) {
          return {
            ...cartItem,
            ...payload,
          };
        }

        return cartItem;
      });

      const cartItemsTotalQuantity = sumCartItems(updatedCartItems);
      const cartItemsTotalValue = getCartItemsTotalValue(
        updatedCartItems as ProductData[]
      );

      return {
        ...state,
        cartItems: updatedCartItems,
        cartItemsTotalQuantity,
        cartItemsTotalValue,
      };
    }

    // set local cart with product data received from backend
    case "cart/set-cart": {
      // prev state
      const previousCartId = state.cartId;
      const previousCartItems = [...state.cartItems];
      const previousSelectedCartItems = [...state.selectedCartItems];

      // new state
      const { cartId, included, companyBusinessUnitKey, employeeDiscount } =
        payload;
      const cartItems = aggregateProductData(included);
      const cartItemsTotalQuantity = sumCartItems(cartItems);
      const cartItemsTotalValue = getCartItemsTotalValue(cartItems);

      const isSameCart = previousCartId && previousCartId === cartId;
      const hasMoreCartItems =
        previousSelectedCartItems.length < cartItems.length;

      // filter selected cart items, if none of the below is true, the prev state is still valid
      let selectedCartItems = previousSelectedCartItems;

      // if new cart id, set all items to selected
      if (!isSameCart) {
        selectedCartItems = cartItems.map((cartItem) => cartItem.sku);
      }

      // same cart but there are more items now
      if (isSameCart && hasMoreCartItems) {
        /*
         * filter all items that are already in the previous state, so we get the new once
         * than generate a list of the skus, so they will be selected by default
         */
        const newSelectedItems = cartItems
          .filter(
            (cartItem) =>
              !previousCartItems.some(
                (previousCartItem) => previousCartItem.sku === cartItem.sku
              )
          )
          .map((cartItem) => cartItem.sku);

        selectedCartItems = [...previousSelectedCartItems, ...newSelectedItems];
      }

      // Remove unavailable skus from selected items
      // e.g. item is not available anymore but still in a users cart
      if (isSameCart) {
        const cartItemsSkus = cartItems.map((cartItem) => cartItem.sku);
        selectedCartItems = selectedCartItems.filter((sku: string) =>
          cartItemsSkus.includes(sku)
        );
      }

      return {
        ...state,
        cartItems,
        cartId,
        selectedCartItems,
        cartItemsTotalQuantity,
        cartItemsTotalValue,
        companyBusinessUnitKey,
        employeeDiscount,
      };
    }

    // add a sku to the selectedCartItems array
    case "cart/add-selected-cart-item": {
      const selectedCartItems = [...state.selectedCartItems];

      if (selectedCartItems.indexOf(payload) < 0) {
        selectedCartItems.push(payload);
      }

      return {
        ...state,
        selectedCartItems,
      };
    }

    // delete a sku from the selectedCartItems array
    case "cart/delete-selected-cart-item": {
      const remainingItemSkus = state.selectedCartItems.filter(
        (currentSku: string) => currentSku !== payload
      );

      return {
        ...state,
        selectedCartItems: remainingItemSkus,
      };
    }

    case "cart/set-alternatives": {
      return {
        ...state,
        cartItemAlternatives: {
          ...state.cartItemAlternatives,
          ...payload,
        },
      };
    }

    case "cart/reset-alternatives": {
      return {
        ...state,
        cartItemAlternatives: payload,
      };
    }

    case "cart/delete-alternative": {
      /*
       * filter out the passed sku (oayload)
       * the keys for the objects are skus
       */
      const cartItemAlternatives = { ...state.cartItemAlternatives };
      //
      const skusToDelete = Array.isArray(payload) ? payload : [payload];

      skusToDelete.forEach((sku: string) => {
        delete cartItemAlternatives[sku];
      });

      return {
        ...state,
        cartItemAlternatives,
      };
    }

    case "cart/set-cart-employee-discount":
      return { ...state, employeeDiscount: payload?.employeeDiscount };

    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default currentCartItemsReducer;
