import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { standingOrderRequestEndpoint } from "../endpoints";
import { StandingOrderRequestItem } from "../../types/standingOrder";

interface PostStandingOrderRequestProps {
  items: StandingOrderRequestItem[];
  cancelTokenSource: CancelTokenSource;
}

const postStandingOrderRequest = ({
  items,
  cancelTokenSource,
}: PostStandingOrderRequestProps) => {
  const payload = {
    data: {
      type: "standing-order-request",
      attributes: {
        items,
      },
    },
  };
  return axiosWithAuth()
    .post(standingOrderRequestEndpoint, payload, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (response.status !== 204) {
        return Promise.reject(response);
      }

      return response;
    });
};

export default postStandingOrderRequest;
