import React, { useCallback, useEffect, useState } from "react";

import clsx from "clsx";
import { shallowEqual } from "react-redux";
import { Space } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { Entry } from "contentful";

import WebshopCategoryVideoModal from "./WebshopCategoryVideoModal";
import { fullDateFormat } from "../../../utils/dateFormats";
import { ContentfulWebshopCategoryVideoEntry } from "../../../types/contentfulWebshopCategoryVideoEntry";
import DefaultImage from "../../../static/images/default-product-image.jpg";

interface WebshopCategoryVideoProps {
  webshopCategoryVideo: Entry<ContentfulWebshopCategoryVideoEntry>;
  className?: string;
}

const PEERTUBE_BASE_URL = "https://peertube.weiling.de";
const PEERTUBE_API_BASE_PATH = "api/v1";
const PEERTUBE_API_VIDEOS_PATH = "videos";

const WebshopCategoryVideo: React.FC<WebshopCategoryVideoProps> = (
  props: WebshopCategoryVideoProps
) => {
  const { webshopCategoryVideo, className } = props;
  const {
    video_id: videoId,
    title,
    video,
    preview_image: previewImage,
    publish_date: publishDate,
  } = webshopCategoryVideo.fields;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState<string>(null);
  const [publishedAt, setPublishedAt] = useState<string>(null);

  const getVideoData = useCallback(async () => {
    if (!videoId) {
      return;
    }

    const url = `${PEERTUBE_BASE_URL}/${PEERTUBE_API_BASE_PATH}/${PEERTUBE_API_VIDEOS_PATH}/${videoId}`;

    setIsLoading(true);

    axios
      .get(url)
      .then((response: any) => {
        if (response?.status !== 200) {
          setHasError(true);
          return Promise.reject(response);
        }

        const { previewPath, createdAt } = response.data;
        setVideoThumbnailUrl(`${PEERTUBE_BASE_URL}${previewPath}`);
        setPublishedAt(createdAt);
        setIsLoading(false);
        setHasError(false);
        return true;
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          setHasError(true);
        }
      });
  }, [videoId]);

  useEffect(() => {
    getVideoData().catch();
  }, [getVideoData]);

  if (isLoading || hasError || (!videoId && !video)) {
    return null;
  }

  return (
    <>
      <Space
        className={clsx("webshop-category-video", className)}
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        <div className="webshop-category-video__thumbnail">
          <div>
            <img
              src={
                videoThumbnailUrl ||
                previewImage?.fields?.file?.url ||
                DefaultImage
              }
              alt={title}
            />
          </div>
          <CaretRightOutlined />
        </div>
        <div className="webshop-category-video__details">
          <h5>{title}</h5>
          {(!!publishedAt || !!publishDate) && (
            <div>
              {moment(publishedAt || publishDate).format(fullDateFormat)}
            </div>
          )}
        </div>
      </Space>
      <WebshopCategoryVideoModal
        webshopCategoryVideo={webshopCategoryVideo}
        videoUrl={
          videoId?.length
            ? `${PEERTUBE_BASE_URL}/videos/embed/${videoId}`
            : video?.fields?.file?.url
        }
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
      />
    </>
  );
};

export default React.memo(WebshopCategoryVideo, (prevProps, nextProps) =>
  shallowEqual(prevProps, nextProps)
);
