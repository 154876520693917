import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Layout, Row } from "antd";

import clearStore from "../../../state/actions/clearStore";
import getContentfulContentType from "../../../api/contentful/getContentfulContentType";
import handleError from "../../../utils/handleError";
import useGetAllCartsCheapMetadata from "../../../hooks/useGetAllCartsCheapMetadata";
import useIsForeignCompanyBusinessUnit from "../../../hooks/useIsForeignCompanyBusinessUnit";
import renderContentfulDocument from "../../../utils/renderContentfulDocument";
import HrDivider from "../../divider/Divider";
import LinkToCart from "../../../Router/LinkToCart";
import { OpenCartsWidget } from "../../molecules";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import {
  contentfulContentTypes,
  pageTitles,
  pageTitleSuffix,
  routePathNames,
  routeProductCategoryKeys,
} from "../../../appConfig";
import { ReactComponent as CheckmarkIcon } from "../../../static/svg/checkmark_purple.svg";
import { RootState } from "../../../types/rootState";

interface OrderConfirmationState {
  remainingItemsInCart?: number;
  redirectToShop?: boolean;
}

const OrderConfirmation: React.FC<OrderConfirmationState> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, hasError, openCarts } = useGetAllCartsCheapMetadata(5);
  const { isForeignCompanyBusinessUnit } = useIsForeignCompanyBusinessUnit();

  const { remainingItemsInCart, redirectToShop = true } =
    (location.state as OrderConfirmationState) || {};

  const { isEmployee = false } =
    useSelector((state: RootState) => state?.userData) || {};
  const { deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );

  const [contentfulData, setContentfulData] = useState<any>({});

  const openCartsWithDetails: any[] = useMemo(
    () => openCarts.slice(0, 2),
    [openCarts]
  );

  useEffect(() => {
    if (isEmployee && isForeignCompanyBusinessUnit) {
      return;
    }

    getContentfulContentType(contentfulContentTypes.orderConfirmation)
      .then((orderConfirmationTexts: any) => {
        if (
          orderConfirmationTexts?.length &&
          orderConfirmationTexts[0]?.fields
        ) {
          setContentfulData(orderConfirmationTexts[0].fields);
        }

        return orderConfirmationTexts;
      })
      .catch(handleError);
  }, [isEmployee, isForeignCompanyBusinessUnit]);

  if (isEmployee && isForeignCompanyBusinessUnit) {
    setTimeout(() => {
      clearStore();
      navigate(routePathNames.login);
    }, 4000);
  }

  if (redirectToShop) {
    return <Navigate to={routePathNames.shop} />;
  }

  return (
    <>
      <TrackingHelmet
        title={pageTitles.orderConfirmation}
        suffix={pageTitleSuffix}
      />

      <ShopCategoryNavigation />

      <Layout className="container-layout container-layout--inner">
        <div
          className={clsx(
            "order-confirmation-page",
            isEmployee && "is-employee",
            isForeignCompanyBusinessUnit && "is-foreign"
          )}
        >
          <CheckmarkIcon />

          {isEmployee && isForeignCompanyBusinessUnit ? (
            <>
              <h1>Vielen Dank für die Bestellung.</h1>
              <p>
                Sie werden gleich ausgeloggt.
                <br />
                Für eine weitere Bestellung melden Sie sich bitte erneut an.
              </p>
            </>
          ) : (
            <>
              {!!contentfulData?.content &&
                renderContentfulDocument(contentfulData.content)}

              <Row gutter={[10, 10]}>
                <Col xs={12} sm={6}>
                  <NavLink
                    to={routePathNames.shop}
                    className="button buttonPrimary buttons"
                  >
                    zurück zur Startseite
                  </NavLink>
                </Col>
                <Col xs={12} sm={6}>
                  <NavLink
                    to={`/wochenplaner/kategorie/${routeProductCategoryKeys.fruitsAndVeggies}`}
                    className="button buttonPrimary buttons"
                  >
                    zum OG-Wochenplaner
                  </NavLink>
                </Col>
              </Row>
              <OpenCartsWidget
                title="Ihre offenen Warenkörbe"
                openCarts={openCartsWithDetails}
                isLoading={isLoading}
                hasError={hasError}
                showCartDetails
                singleColMode={false}
              />
              {remainingItemsInCart > 0 && (
                <>
                  <HrDivider spacingTop="2xl" spacingBottom="2xl" size={2} />

                  <h3>
                    Du hast noch {remainingItemsInCart} Artikel in deinem
                    Warenkorb.
                  </h3>

                  <p>
                    <LinkToCart deliveryDate={deliveryDate}>
                      Artikel im Warenkorb ansehen
                    </LinkToCart>
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

export default OrderConfirmation;
