import { useMemo } from "react";
import { useSelector } from "react-redux";

import { BusinessUnit } from "../types/businessUnits";
import { RootState } from "../types/rootState";

const useIsForeignCompanyBusinessUnit = () => {
  const {
    businessUnit,
    businessUnits,
  }: { businessUnit: BusinessUnit; businessUnits: BusinessUnit[] } =
    useSelector((state: RootState) => state?.userData);

  const isForeignCompanyBusinessUnit = useMemo(() => {
    const defaultCompanyBusinessUnit = businessUnits.find(
      (bu: BusinessUnit) => bu?.isDefault === true
    );

    if (!defaultCompanyBusinessUnit) {
      return false;
    }

    return defaultCompanyBusinessUnit?.idCompany !== businessUnit?.idCompany;
  }, [businessUnits, businessUnit]);

  return {
    isForeignCompanyBusinessUnit,
  };
};

export default useIsForeignCompanyBusinessUnit;
