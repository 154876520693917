import React, { useRef, useState, useEffect, useCallback } from "react";

import clsx from "clsx";
import { debounce } from "debounce";
import { Input, AutoComplete, Spin } from "antd";
import axios, { CancelTokenSource } from "axios";

import useCancelAxiosOnUnmount from "../../../../hooks/useCancelAxiosOnUnmount";
import createSuggestions from "../../../../utils/suggest/createSuggestions";
import getDeliveryDateBasedAttributes from "../../../product/getDeliveryDateBasedAttributes";
import getProductDetail from "../../../../api/products/getProductDetail";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import postStocktakingItem from "../../../../api/stocktaking/postStocktakingItem";
import { getValidDeliveryDate } from "../../../../utils/datePicker";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../../api/getCancelTokenSource";
import { productAttributes } from "../../../../api/productAttributes";
import { apiDateFormat } from "../../../../utils/dateFormats";
import { delays } from "../../../../appConfig";

interface StocktakingProductSearchProps {
  stocktakingId: number;
  setLoadingCallback?: (value: boolean) => void;
  canAdd?: boolean;
  onAdded?: () => void;
  searchDelay?: number;
  className?: string;
}

const StocktakingProductSearch: React.FC<StocktakingProductSearchProps> = (
  props: StocktakingProductSearchProps
) => {
  const {
    stocktakingId,
    setLoadingCallback = () => {},
    canAdd = true,
    onAdded = () => {},
    searchDelay = delays.stocktakingProductSearch,
    className,
  } = props;

  const inputRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [options, setOptions] = useState<null | any[]>(null);
  const [selectedItemSku, setSelectedItemSku] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const resetComponentStates = () => {
    inputRef?.current?.blur();
    setSelectedItemSku("");
    setOptions(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doSearch = useCallback(
    debounce((value: string) => {
      if (value.length < 3) {
        return;
      }

      cancelTokenSource.current = cancelAndRenewCancelToken(
        cancelTokenSource.current
      );

      setIsLoading(true);
      createSuggestions({
        query: value,
        deliveryDate: getValidDeliveryDate().format(apiDateFormat),
        cancelTokenSource: cancelTokenSource.current,
        type: "quickOrder",
      })
        .then((result: any) => {
          setOptions(result);
          setIsLoading(false);
          inputRef?.current?.focus();
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setOptions(null);
            setIsLoading(false);
          }
        });
    }, searchDelay),
    []
  );

  const onSelect = async (value: string) => {
    setSelectedItemSku(value);
    setLoadingCallback(true);

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    const productDetails = await getProductDetail({
      productSku: value,
      deliveryDate: getValidDeliveryDate().format(apiDateFormat),
      cancelTokenSource: cancelTokenSource.current,
    }).catch((error) => {
      if (!axios.isCancel(error)) {
        setLoadingCallback(false);
      }
      requestCatchHandler(error);
    });

    if (!productDetails) {
      setLoadingCallback(false);
      return;
    }

    const {
      idProductConcrete,
      attributes,
      brand,
      name,
      sku,
      vpeQuantity,
      vpeText,
      prices,
    } = productDetails?.concreteProducts?.[0] || {};
    const {
      [productAttributes.vatGroup]: vatGroup,
      [productAttributes.gtin]: eanCode,
      [productAttributes.unitQuantity]: productUnitQuantity,
      [productAttributes.weighingArticle]: weighingArticle,
    } = attributes;
    const taxRate = Number(vatGroup?.replace("%", "")) || 19;
    const basePriceQuantityNumber = parseFloat(productUnitQuantity) || 1;
    const { defaultPrice } = getDeliveryDateBasedAttributes({
      deliveryDate: getValidDeliveryDate().format(apiDateFormat),
      prices,
    });

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    postStocktakingItem({
      stocktakingId,
      eanCode: eanCode?.split(",")?.[0] || eanCode || sku,
      sku,
      quantity: 1,
      label: name,
      unitVpe: `${vpeQuantity}x ${vpeText}`,
      unitPrice: Math.round(
        weighingArticle === "1"
          ? defaultPrice
          : defaultPrice / basePriceQuantityNumber
      ),
      taxRate,
      producer: brand,
      idProduct: Number(idProductConcrete),
      cancelTokenSource: cancelTokenSource.current,
    })
      .then(() => {
        resetComponentStates();
        setLoadingCallback(false);
        onAdded();
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          resetComponentStates();
          setLoadingCallback(false);
        }
        requestCatchHandler(error);
      });
  };

  useEffect(() => {
    const onScroll = () => {
      if (selectedItemSku.length > 0) {
        resetComponentStates();
      }
    };
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [selectedItemSku]);

  useEffect(() => {
    doSearch(selectedItemSku);
  }, [doSearch, selectedItemSku]);

  return (
    <div
      className={clsx("quickOrder", "stocktaking-product-search", className)}
      ref={containerRef}
    >
      {isLoading && <Spin size="small" className="quickOrderLoading" />}

      <AutoComplete
        ref={inputRef}
        options={options}
        onSelect={onSelect}
        disabled={!canAdd}
        open={selectedItemSku.length > 0 && isInputFocused && !isLoading}
        notFoundContent="Keine Artikel gefunden."
        dropdownStyle={{
          minWidth: containerRef.current?.clientWidth || 240,
        }}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        allowClear
        onClear={resetComponentStates}
        value={selectedItemSku}
        dropdownClassName="stocktaking-product-search__search"
      >
        <Input
          size="large"
          placeholder="Suche nach Art.-Nr."
          onChange={(e: React.BaseSyntheticEvent) =>
            setSelectedItemSku(e.target.value)
          }
          inputMode="numeric"
          pattern="[0-9]*"
        />
      </AutoComplete>
    </div>
  );
};

export default StocktakingProductSearch;
