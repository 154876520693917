import React, { BaseSyntheticEvent, ReactNode } from "react";

import clsx from "clsx";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { Space } from "antd";

interface PercentNumberInputProps {
  value?: string | number;
  className?: string;
  onChange?: (e: BaseSyntheticEvent) => void;
  addonBefore?: ReactNode;
  addonAfter?: ReactNode;
  maxValue?: number;
  disabled?: boolean;
}

const PercentNumberInput: React.FC<PercentNumberInputProps> = (
  props: PercentNumberInputProps
) => {
  const {
    value,
    className,
    onChange,
    addonBefore,
    addonAfter,
    maxValue = 100,
    disabled,
  } = props;

  return (
    <Space className={clsx("percent-number-input", className)}>
      {addonBefore}
      <NumericFormat
        isAllowed={(values: NumberFormatValues) => {
          const { floatValue, value: val } = values;
          return floatValue <= maxValue || val === "";
        }}
        value={value}
        className="ant-input"
        allowNegative={false}
        decimalScale={2}
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        pattern="[0-9]*"
        inputMode="tel"
        style={{ width: "70px" }}
        onChange={onChange}
        disabled={disabled}
      />
      {addonAfter || <span>%</span>}
    </Space>
  );
};

export default PercentNumberInput;
