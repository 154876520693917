import React, { useRef, useState } from "react";

import clsx from "clsx";
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputRef,
  message,
  Select,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios, { CancelTokenSource } from "axios";
import moment from "moment/moment";

import getProductByEan from "../../../../api/scanner/getProductByEan";
import getProductDetail from "../../../../api/products/getProductDetail";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../../api/getCancelTokenSource";
import useMedia from "../../../../hooks/useMedia";
import getCssVariable from "../../../../utils/getCssVariable";
import useCancelAxiosOnUnmount from "../../../../hooks/useCancelAxiosOnUnmount";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import getDeliveryDateBasedAttributes from "../../../product/getDeliveryDateBasedAttributes";
import QuickOrderItem from "../../../pages/QuickOrder/QuickOrderItems/QuickOrderItem";
import { messageData } from "../../../../appConfig";
import { fullDateFormat } from "../../../../utils/dateFormats";
import { ProductData } from "../../../../types/productData";
import { StandingOrderRequestStateItem } from "../../../../types/standingOrder";

interface StandingOrderRequestModalFormProps {
  form: FormInstance;
  deliveryDate: string;
  product: ProductData;
  setProduct: (product: ProductData) => void;
  isSearching: boolean;
  setIsSearching: (isSearching: boolean) => void;
  className?: string;
  items: StandingOrderRequestStateItem[];
  setItems?: (items: StandingOrderRequestStateItem[]) => void;
}

const MAX_ITEMS = 20;

const StandingOrderRequestModalForm: React.FC<
  StandingOrderRequestModalFormProps
> = (props: StandingOrderRequestModalFormProps) => {
  const {
    form,
    deliveryDate,
    product = null,
    setProduct = () => {},
    isSearching = false,
    setIsSearching = () => {},
    className,
    items,
    setItems,
  } = props;

  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-xl")})`
  );

  const [isUnavailable, setIsUnavailable] = useState<boolean>(false);

  const skuInputRef = useRef<InputRef>(null);
  const quantityInputRef = useRef<InputRef>(null);
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const searchProduct = async () => {
    const query = form.getFieldValue("sku")?.trim();

    if (!query) {
      return;
    }

    const isEanSearch = query?.length > 8;

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    let products = [];

    setIsSearching(true);

    try {
      products = isEanSearch
        ? await getProductByEan(query, deliveryDate, cancelTokenSource.current)
        : await getProductDetail({
            productSku: query,
            deliveryDate,
            cancelTokenSource: cancelTokenSource.current,
          });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsSearching(false);
        message.error(messageData.error.cart.addItem);
      }
      requestCatchHandler(error);
      return;
    }

    setIsSearching(false);

    const concreteProduct = isEanSearch
      ? products?.[0]
      : products?.concreteProducts?.[0];

    if (!concreteProduct) {
      message.info(messageData.info.quickOrder.notFoundItem);
      setTimeout(() => skuInputRef?.current?.focus(), 100);
      return;
    }

    setProduct(concreteProduct);

    const { availabilities } = concreteProduct;

    const { isUnavailable: isProductUnavailable } =
      getDeliveryDateBasedAttributes({
        deliveryDate,
        availabilities,
      });

    setIsUnavailable(isProductUnavailable);

    if (isProductUnavailable) {
      setTimeout(() => skuInputRef?.current?.select(), 100);
    } else {
      setTimeout(() => quantityInputRef?.current?.select(), 100);
    }
  };

  const handleOnFinish = () => {
    if (items?.length >= MAX_ITEMS) {
      message.info(messageData.info.standingOrderRequest.maxItems);
      return;
    }

    const newItem: StandingOrderRequestStateItem = {
      sku: product?.sku,
      quantity: form.getFieldValue("quantity"),
      weekdayShipmentDate: form.getFieldValue("weekdayShipmentDate"),
      interval: form.getFieldValue("interval"),
      startDate: form.getFieldValue("startDate")?.format(fullDateFormat),
      endDate: form.getFieldValue("endDate")
        ? form.getFieldValue("endDate").format(fullDateFormat)
        : null,
      product,
    };

    const itemIndex = items.findIndex(
      (item: StandingOrderRequestStateItem) =>
        newItem?.sku === item?.sku &&
        newItem?.weekdayShipmentDate === item?.weekdayShipmentDate
    );

    if (itemIndex !== -1) {
      message.info(messageData.info.standingOrderRequest.duplicateItem);
      return;
    }

    setItems([newItem, ...items]);
    setProduct(null);
    form.resetFields();
    setTimeout(() => skuInputRef?.current?.focus(), 100);
  };

  return (
    <div className={clsx("standing-order-request-modal-form", className)}>
      <Form
        form={form}
        initialValues={{
          sku: "",
          quantity: "1",
          productName: "",
          weekdayShipmentDate: "Montag",
          interval: "",
          startDate: "",
          endDate: "",
        }}
        layout={browserIsDesktop ? "inline" : "vertical"}
        onFinish={handleOnFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Artikelnr. / EAN"
          name="sku"
          pattern="[0-9]*"
          rules={[
            { required: true, message: "Artikelnr. / EAN angeben" },
            { min: 5 },
            { max: 13 },
          ]}
          className="standing-order-request-modal-form__sku"
        >
          <Input
            disabled={isSearching}
            ref={skuInputRef}
            maxLength={13}
            allowClear
            suffix={isSearching && <Spin size="small" />}
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={() => {
              setProduct(null);
            }}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.code === "Escape") {
                setProduct(null);
                form.setFieldValue("sku", "");
              }
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.code === "Tab") {
                setProduct(null);
                searchProduct();
              }
            }}
            onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
              e.preventDefault();
              setProduct(null);
              searchProduct();
            }}
            addonAfter={
              <Button
                icon={<SearchOutlined />}
                onClick={() => {
                  setProduct(null);
                  searchProduct();
                }}
              />
            }
          />
        </Form.Item>
        <Form.Item
          label="Menge"
          name="quantity"
          pattern="[0-9]*"
          rules={[
            { required: true, message: "Menge angeben" },
            { min: 1 },
            { max: 3 },
          ]}
          className="standing-order-request-modal-form__quantity"
        >
          <Input
            disabled={isSearching || !product || isUnavailable}
            ref={quantityInputRef}
            maxLength={3}
            addonAfter="Stück"
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </Form.Item>
        <Form.Item
          label="Liefertag"
          name="weekdayShipmentDate"
          className="standing-order-request-modal-form__weekday_shipment_date"
          rules={[{ required: true }]}
        >
          <Select
            disabled={isSearching || !product || isUnavailable}
            options={[
              { value: "Montag" },
              { value: "Dienstag" },
              { value: "Mittwoch" },
              { value: "Donnerstag" },
              { value: "Freitag" },
              { value: "Samstag" },
            ]}
            style={{ width: 100 }}
          />
        </Form.Item>
        <Form.Item
          label="Intervall"
          name="interval"
          rules={[{ required: true, message: "Intervall angeben" }]}
          className="standing-order-request-modal-form__interval"
        >
          <Input
            disabled={isSearching || !product || isUnavailable}
            style={{ width: 120 }}
          />
        </Form.Item>
        <Form.Item
          label="Startdatum"
          name="startDate"
          rules={[{ required: true, message: "Startdatum wählen" }]}
          className="standing-order-request-modal-form__start_date"
        >
          <DatePicker
            disabled={isSearching || !product || isUnavailable}
            format={fullDateFormat}
            disabledDate={(date) => date.isBefore(moment(), "day")}
          />
        </Form.Item>
        <Form.Item
          label="Enddatum"
          name="endDate"
          rules={[{ required: false }]}
          className="standing-order-request-modal-form__end_date"
        >
          <DatePicker
            disabled={isSearching || !product || isUnavailable}
            format={fullDateFormat}
            disabledDate={(date) =>
              date.isBefore(form.getFieldValue("startDate"), "day")
            }
          />
        </Form.Item>
        <Form.Item label="">
          <Button
            htmlType="submit"
            className="button buttonPrimary"
            disabled={isSearching || !product || isUnavailable}
          >
            Hinzufügen
          </Button>
        </Form.Item>
      </Form>
      {!!product?.sku && (
        <div className="standing-order-request-modal-form__result">
          <div>Vorschau</div>
          <QuickOrderItem
            deliveryDate={deliveryDate}
            item={{ quantity: 0, product, addedAt: moment() }}
            isPreview
          />
        </div>
      )}
    </div>
  );
};

export default StandingOrderRequestModalForm;
