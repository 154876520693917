import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import { Input, message, Modal, Radio, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import useSetCartAndUserLists from "../../../hooks/useSetCartAndUserLists";
import clearStore from "../../../state/actions/clearStore";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import postSwitchBusinessUnit from "../../../api/postSwitchBusinessUnit";
import requestCatchHandler from "../../../api/requestCatchHandler";
import { filterByAddressAndKey, sortByOwnershipAndKey } from "./utils";
import BusinessUnitModalItem from "./BusinessUnitModalItem/BusinessUnitModalItem";
import HrDivider from "../../divider/Divider";
import LoaderComponent from "../../atoms/LoaderComponent";
import { messageData, routePathNames } from "../../../appConfig";
import { BusinessUnit } from "../../../types/businessUnits";
import { RootState } from "../../../types/rootState";

interface BusinessUnitModalProps {
  visible?: boolean;
  closable?: boolean;
  businessUnit?: BusinessUnit;
  hideModal?: () => void;
}

const MESSAGE_KEY_SWITCH_IN_PROGRESS = "BU_SWITCH_IN_PROGRESS";
const MODAL_CANCEL_BUTTON_ID = "logoutButton";

const BusinessUnitModal: React.FC<BusinessUnitModalProps> = ({
  visible = false,
  closable = false,
  businessUnit: currentBusinessUnit,
  hideModal,
}: BusinessUnitModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cancelTokenSource = useRef(getCancelTokenSource());
  const didLoadDefaultBusinessUnitRef = useRef<boolean>(false);

  const { accessToken } = useSelector((state: RootState) => state.auth);
  const {
    businessUnits,
    businessUnit,
  }: { businessUnits: BusinessUnit[]; businessUnit: BusinessUnit } =
    useSelector((state: RootState) => state.userData);

  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [businessUnitList, setBusinessUnitList] =
    useState<BusinessUnit[]>(businessUnits);
  const [selectedBusinessUnit, setSelectedBusinessUnit] =
    useState<BusinessUnit | null>(currentBusinessUnit);

  const setupLocalCartAndOrders = useSetCartAndUserLists({
    onSuccess: () => {
      setIsLoading(false);
      message.destroy(MESSAGE_KEY_SWITCH_IN_PROGRESS);
    },
  });

  const defaultBusinessUnit = useMemo(
    () => businessUnits.find((bu: BusinessUnit) => bu?.isDefault === true),
    [businessUnits]
  );

  const numFilteredDefaultBusinessUnits = useMemo(
    () =>
      businessUnitList?.filter(
        (bu: BusinessUnit) => bu?.idCompany === defaultBusinessUnit?.idCompany
      )?.length,
    [defaultBusinessUnit, businessUnitList]
  );

  const hasFilteredForeignBusinessUnits = useMemo(
    () =>
      businessUnitList?.findIndex(
        (bu: BusinessUnit) => bu.idCompany !== defaultBusinessUnit?.idCompany
      ) !== -1,
    [defaultBusinessUnit, businessUnitList]
  );

  const onAccept = useCallback(
    (newBusinessUnit: BusinessUnit) => {
      const shouldSkipSwitching =
        !newBusinessUnit?.companyBusinessUnitKey ||
        newBusinessUnit?.uuid === currentBusinessUnit?.uuid;

      setIsVisible(false);
      setBusinessUnitList(businessUnits);

      if (typeof hideModal === "function") hideModal();

      if (shouldSkipSwitching) {
        return;
      }

      setIsLoading(true);
      message.loading({
        content: currentBusinessUnit
          ? messageData.loading.businessUnit.switch.content
          : messageData.loading.businessUnit.load.content,
        duration: 0,
        key: MESSAGE_KEY_SWITCH_IN_PROGRESS,
      });

      postSwitchBusinessUnit(newBusinessUnit.uuid, cancelTokenSource.current)
        .then((response) => {
          dispatch({
            payload: { businessUnit: newBusinessUnit },
            type: "user/set-business-unit",
          });

          dispatch({
            type: "user/update-user",
            payload: response.data?.data?.attributes,
          });

          dispatch({
            type: "alreadyOrdered/empty-order-item-index",
          });

          message.success(
            messageData.success.auth.businessUnit.content.replace(
              "{{businessUnit}}",
              String(newBusinessUnit.companyBusinessUnitKey)
            )
          );
        })
        .then(() => {
          setupLocalCartAndOrders();
        })
        .then(() => {
          navigate(routePathNames.dashboard);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setIsLoading(false);
            setIsVisible(true);
            message.error(messageData.error.auth.setBusinessUnit);
          }
          requestCatchHandler(error);
        });
    },
    [
      currentBusinessUnit,
      dispatch,
      hideModal,
      navigate,
      setupLocalCartAndOrders,
      businessUnits,
    ]
  );

  const handleModalClose = (e: any) => {
    setIsVisible(false);
    setBusinessUnitList(businessUnits);

    if (typeof hideModal === "function") hideModal();

    if (e.currentTarget.id === MODAL_CANCEL_BUTTON_ID) {
      clearStore();
    }
  };

  const handleItemClick = (value: string) => {
    setSelectedBusinessUnit(
      businessUnitList[businessUnitList.findIndex((bu) => bu.uuid === value)]
    );
  };

  const handleInputChange = (e: any) => {
    setBusinessUnitList(filterByAddressAndKey(businessUnits, e.target.value));
  };

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  useEffect(() => {
    setSelectedBusinessUnit(currentBusinessUnit);
  }, [currentBusinessUnit]);

  useEffect(() => {
    if (businessUnits?.length === (0 || undefined)) {
      message.error(messageData.error.auth.getBusinessUnit);
    }
    if (accessToken && businessUnits?.length > 1 && businessUnit === null) {
      setBusinessUnitList(businessUnits);
      setIsVisible(true);
    }
  }, [accessToken, businessUnits, businessUnit]);

  useEffect(() => {
    const shouldLoadDefaultBusinessUnit =
      businessUnits?.length === 1 &&
      !didLoadDefaultBusinessUnitRef.current &&
      !selectedBusinessUnit;

    if (shouldLoadDefaultBusinessUnit) {
      didLoadDefaultBusinessUnitRef.current = true;
      onAccept(businessUnits[0]);
    }
  }, [businessUnits, onAccept, selectedBusinessUnit]);

  if (businessUnits?.length < 2) {
    return null;
  }

  return (
    <>
      {isLoading && <LoaderComponent />}
      <Modal
        title={<h3>Wählen Sie die gewünschte Filiale aus</h3>}
        visible={isVisible}
        onOk={() => onAccept(selectedBusinessUnit)}
        onCancel={handleModalClose}
        okText={currentBusinessUnit ? "Wechseln" : "Auswählen"}
        okButtonProps={{
          disabled:
            !selectedBusinessUnit ||
            selectedBusinessUnit?.uuid === null ||
            isLoading ||
            currentBusinessUnit?.uuid === selectedBusinessUnit?.uuid,
        }}
        cancelButtonProps={{
          id: MODAL_CANCEL_BUTTON_ID,
          style: { visibility: closable ? "hidden" : "visible" },
        }}
        cancelText="Logout"
        closable={closable}
        maskClosable={closable}
        keyboard={closable}
        destroyOnClose
        className="business-unit-modal"
        width={700}
      >
        <Input
          allowClear
          placeholder="Filiale suchen..."
          onChange={handleInputChange}
          prefix={<SearchOutlined />}
        />
        {businessUnitList?.length > 0 ? (
          <div className="business-unit-list">
            <Radio.Group value={selectedBusinessUnit?.uuid} size="large">
              <Space direction="vertical">
                {businessUnitList
                  .sort((bu1: BusinessUnit, bu2: BusinessUnit) =>
                    sortByOwnershipAndKey(bu1, bu2, defaultBusinessUnit)
                  )
                  .map((bu: BusinessUnit, index: number) => {
                    const shouldShowDivider =
                      hasFilteredForeignBusinessUnits &&
                      !!numFilteredDefaultBusinessUnits &&
                      index === numFilteredDefaultBusinessUnits;

                    return (
                      <div key={bu.uuid}>
                        {shouldShowDivider && (
                          <HrDivider spacingTop="s" spacingBottom="m" />
                        )}
                        <BusinessUnitModalItem
                          businessUnit={bu}
                          selected={(() =>
                            selectedBusinessUnit?.uuid ===
                            businessUnitList[index].uuid)()}
                          value={bu.uuid}
                          onItemClick={(value) => handleItemClick(value)}
                        />
                      </div>
                    );
                  })}
              </Space>
            </Radio.Group>
          </div>
        ) : (
          <div className="business-unit-missing">
            Es wurde keine Filiale über die Sucheingabe gefunden.
          </div>
        )}
      </Modal>
    </>
  );
};

export default BusinessUnitModal;
