import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Layout,
  Row,
  Col,
  message,
  Modal,
  Spin,
  Affix,
  Input,
  Button,
} from "antd";
import axios, { AxiosError, CancelTokenSource } from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import HrDivider from "../divider/Divider";
import ProductItemTable from "../ProductItemTable/ProductItemTable";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../api/getCancelTokenSource";
import requestCatchHandler from "../../api/requestCatchHandler";
import {
  itemsPerPage,
  locationSearchQueryParameter,
  messageData,
  pageTitles,
  pageTitleSuffix,
} from "../../appConfig";
import ShopCategoryNavigation from "../navigation/ShopCategoryNavigation";
import useGetDeliveryDate from "../../hooks/useGetDeliveryDate";
import getProductsOnPriceTagList from "../../api/priceTagList/getProductsOnPriceTagList";
import { ProductData } from "../../types/productData";
import { AttributeName, productAttributes } from "../../api/productAttributes";
import compareProductCollectionGroups from "../../utils/compareProductCollectionGroups";
import sortProductsBySortIndex from "../../utils/sortProductsBySortIndex";
import PriceTagListChildRow from "./PriceTagListChildRow";
import EmptyListContent from "../EmptyListContent/EmptyListContent";
import {
  PriceTagListEntry,
  PriceTagListPrintType,
} from "../../types/priceTagList";
import postPrintPriceTagList from "../../api/priceTagList/postPrintPriceTagList";
import useCancelAxiosOnUnmount from "../../hooks/useCancelAxiosOnUnmount";
import deleteProductsFromPriceTagList from "../../api/priceTagList/deleteProductsFromPriceTagList";
import QuickSelectProductInput from "../FavouriteList/QuickSelectProductInput";
import postAddProductToPriceTagList from "../../api/priceTagList/postAddProductToPriceTagList";
import disabledDatePickerDates from "../../utils/disabledDatePickerDates";
import BackButton from "../backButton/BackButton";
import elementScrollToPosition from "../../utils/elementScrollToPosition";
import useUpdateUrlFragments from "../../hooks/useUpdateUrlFragments";
import DateSwitcher from "../DateSwitcher/DateSwitcher";
import PriceTagCreamCheese from "../../static/docs/etiketten-frischkaese-blanko.pdf";
import CoverImageCreamCheese from "../../static/images/price-tag-frischkaese.jpg";
import PriceTagOgBlack from "../../static/docs/etiketten-og-schwarz.pdf";
import CoverImageOgBlack from "../../static/images/price-tag-og-schwarz.jpg";
import PriceTagOgWhite from "../../static/docs/etiketten-og-weiss.pdf";
import CoverImageOgWhite from "../../static/images/price-tag-og-weiss.jpg";
import PriceTagWeeklyOfferBioladen from "../../static/docs/etiketten-wochenaktion-bioladen.pdf";
import CoverImageWeeklyOfferBioladen from "../../static/images/price-tag-wochenaktion-bioladen.jpg";
import PriceTagWeeklyOfferBlank from "../../static/docs/etiketten-wochenaktion-blanko.pdf";
import CoverImageWeeklyOfferBlank from "../../static/images/price-tag-wochenaktion-blanko.jpg";
import PriceTagWeeklyOfferLong from "../../static/docs/etiketten-wochenaktion-lang.pdf";
import CoverImageWeeklyOfferLong from "../../static/images/price-tag-wochenaktion-lang.jpg";
import { DropdownButton, LinkedThumbnail } from "../atoms";
import ButtonBackToTop from "../buttons/ButtonBackToTop";
import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";
import postOrderPriceTags from "../../api/priceTagList/postOrderPriceTags";
import { RootState } from "../../types/rootState";
import TrackingHelmet from "../Matomo/TrackingHelmet";
import getProductUVP from "../../utils/getProductUVP";
import { ReactComponent as Delete } from "../../static/svg/delete.svg";
import updateProductsOnPriceTagList from "../../api/priceTagList/updateProductsOnPriceTagList";
import setPriceTagList from "../../state/actions/setPriceTagList";

const { TextArea } = Input;

// Table columns for large screens
const largeScreenColumns = [
  {
    title: "",
    dataIndex: "articleElement",
    width: 100,
  },
  {
    title: "EK-Preis",
    dataIndex: "purchasePrice",
    width: 140,
  },
  {
    title: "Preis auf Etikett",
    dataIndex: "price",
    width: 100,
  },
  {
    title: "UVP Verkaufseinheit",
    dataIndex: "unitPrice",
    width: 180,
  },
  {
    title: "kalkul. UVP",
    dataIndex: "rrp",
    width: 140,
  },
  {
    title: "",
    dataIndex: "interactionIcon",
    width: 50,
  },
];

// Table columns for small screens
const smallScreenColumns = [
  {
    title: "",
    dataIndex: "articleElement",
    width: 100,
  },
];

interface ProductItemShort {
  sku: string;
  name: string;
}

/**
 * price tag list component
 * @constructor
 */
const PriceTagList = function PriceTagList() {
  const isSmallScreen = useMedia(
    `(max-width: ${getCssVariable("screen-md-max")})`
  );
  const isXsScreen = useMedia(
    `(max-width: ${getCssVariable("screen-xs-max")})`
  );
  const columns = isSmallScreen ? smallScreenColumns : largeScreenColumns;
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [deliveryDate] = useGetDeliveryDate();
  const { stockName } = useSelector((state: any) => state.userData);
  const { priceDate, priceTags, printTypes, selectedPriceTagItems } =
    useSelector((state: any) => state.priceTagList);
  const { contactPerson } = useSelector((state: RootState) => state.userData);

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isGeneratingPrint, setIsGeneratingPrint] = useState(false);
  const [productItems, setProductItems] = useState([]);
  const [allProductItems, setAllProductItems] = useState<ProductItemShort[]>(
    []
  );
  const [paginationData, setPaginationData] = useState({
    current: 1,
    total: priceTags.length,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
    selectedPriceTagItems
  );
  const [showDeleteSelectedItemModal, setShowDeleteSelectedItemModal] =
    useState(false);
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [clearModalVisible, setClearModalVisible] = useState(false);
  const [blankModalVisible, setBlankModalVisible] = useState<boolean>(false);
  const [orderModalVisible, setOrderModalVisible] = useState<boolean>(false);
  const [orderContent, setOrderContent] = useState<string>("");
  const [isOrderProcessing, setIsOrderProcessing] = useState<boolean>(false);
  const [orderContentReset, setOrderContentReset] = useState<boolean>(false);

  const hasItemsOnPriceTagList = useMemo(
    () => productItems?.length > 0 && priceTags?.length > 0,
    [productItems, priceTags]
  );

  const hasMountedRef = useRef<boolean>(false);
  const requestsCancelTokenSource = useRef<CancelTokenSource>(
    getCancelTokenSource()
  );
  useCancelAxiosOnUnmount(requestsCancelTokenSource.current);

  const postCancelTokenSource = useRef<CancelTokenSource>(
    getCancelTokenSource()
  );
  useCancelAxiosOnUnmount(postCancelTokenSource.current);

  const updateCancelTokenSource = useRef<CancelTokenSource>(
    getCancelTokenSource()
  );
  useCancelAxiosOnUnmount(updateCancelTokenSource.current);

  const refreshCancelTokenSource = useRef<CancelTokenSource>(
    getCancelTokenSource()
  );
  useCancelAxiosOnUnmount(refreshCancelTokenSource.current);

  const setUpdateUrlFragments = useUpdateUrlFragments();
  const { [locationSearchQueryParameter.page]: pageQuery } =
    queryString.parse(search);

  // message content
  const {
    error: { unexpected: unexpectedMsg },
    success: {
      priceTagList: { addToList: addToListMsg },
    },
  } = messageData;

  /**
   * check if item is already added to the price tag list
   * @param {string} sku
   * @returns {boolean}
   */
  const isItemOnPriceTagList = (sku: string): boolean => {
    return priceTags.some(
      (priceTagEntry: PriceTagListEntry) => priceTagEntry.sku === sku
    );
  };

  /**
   * catch handler for on click update of price tag list
   * @param {AxiosError} error
   */
  const rejectUpdatePriceTagListHandler = (error: AxiosError) => {
    // revert the state change again on failure
    setIsLoading(false);
    if (!axios.isCancel(error)) {
      message.error(unexpectedMsg);
    }

    requestCatchHandler(error);
  };

  /**
   * get products from priceTagList and update state
   */
  const getLabelProducts = useCallback(() => {
    setIsLoading(true);

    return getProductsOnPriceTagList({
      cancelTokenSource: requestsCancelTokenSource.current,
      deliveryDate: priceDate,
      page: Number(pageQuery) || 1,
      pageSize: itemsPerPage.products,
    })
      .then(({ concreteProducts, pagination }) => {
        setProductItems(concreteProducts);

        setPaginationData({
          current: pagination.currentPage,
          total: pagination.numFound,
        });

        // Collect all (unique) product items (=> keep data while paginating)
        setAllProductItems((prevState: ProductItemShort[]) => {
          const allItems = [
            ...prevState,
            ...concreteProducts.map((productData: ProductData) => {
              return { sku: productData.sku, name: productData.name };
            }),
          ];
          // Make product items unique
          return allItems.reduce((items, item) => {
            if (
              !items.find(
                (productItem: ProductItemShort) => productItem.sku === item.sku
              )
            ) {
              items.push(item);
            }
            return items;
          }, []);
        });

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);

        if (!axios.isCancel(error)) {
          message.error(messageData.error.unexpected);
        }

        requestCatchHandler(error);
      });
  }, [requestsCancelTokenSource, priceDate, pageQuery]);

  /**
   * handle click based on state
   * alter state only after success of async call
   */
  const onSubmitValue = (sku: string) => {
    setIsLoading(true);

    return postAddProductToPriceTagList({
      sku:
        sku
          ?.split(/[,|]/)
          ?.map((s: string) => s.trim())
          ?.filter(Boolean) || [],
      cancelTokenSource: postCancelTokenSource.current,
    })
      .then(getLabelProducts)
      .then(() => {
        message.success(addToListMsg);
      })
      .catch((error) => rejectUpdatePriceTagListHandler(error));
  };

  /**
   * wrapper to dispatch new list date to redux
   * @param {string} date
   */
  const setPriceRequestDate = useCallback(
    (date: string) => {
      dispatch({
        type: "priceTagList/set-priceDate",
        payload: {
          priceDate: date,
        },
      });
    },
    [dispatch]
  );

  /**
   * create product groups like in the cart or weekplanner
   * @param cancelTokenSource {CancelTokenSource}
   * @param products {ProductData[]}
   * @return {Array<PriceTagListColumns>}
   */
  const createProductItemRows = useCallback(
    (cancelTokenSource: CancelTokenSource, products: ProductData[]) =>
      products.reduce(
        (accumulator: any[], currentProductEntry: ProductData) => {
          const { attributes } = currentProductEntry;

          let currentProductGroupList: Record<string, any>;

          // find product group from attributes
          const currentProductGroup =
            attributes[
              productAttributes[`productGroup_${stockName}` as AttributeName]
            ];

          const matchingProductGroup = accumulator.find(
            (itemGroup: any) => itemGroup.productGroup === currentProductGroup
          );

          // ALL other productGroups that are NOT currentProductGroup
          const notMatchingProductGroup = accumulator.filter(
            (itemGroup: any) => itemGroup.productGroup !== currentProductGroup
          );

          // if no matching group by product group is available
          currentProductGroupList = compareProductCollectionGroups({
            productGroup: matchingProductGroup,
            lookupGroup: currentProductGroup,
            groupType: "productGroup",
          });

          // Render toggle icon for small devices
          if (isSmallScreen) {
            currentProductGroupList = {
              ...currentProductGroupList,
              articleElement: (
                <div className="cartItemGroup--small">
                  <span className="cartItemGroupHeading">
                    {currentProductGroupList.productGroup}
                  </span>
                  {currentProductGroupList.interactionIcon}
                </div>
              ),
            };
          }

          // fill with current item
          currentProductGroupList = {
            ...currentProductGroupList,
            children: [
              ...currentProductGroupList.children,
              PriceTagListChildRow({
                deliveryDate: priceDate,
                productItem: currentProductEntry,
                isSmallScreen,
              }),
            ].sort(sortProductsBySortIndex),
          };

          return [...notMatchingProductGroup, currentProductGroupList];
        },
        []
      ),
    [priceDate, stockName, isSmallScreen]
  );

  // memoize the rows array to prevent unnecessary lifecycles
  const visibleProductGroupRows = useMemo(() => {
    const productsToShow = productItems.filter((singleProductData) =>
      priceTags.some(
        (priceTagEntry: PriceTagListEntry) =>
          priceTagEntry.sku === singleProductData.sku
      )
    );

    return createProductItemRows(
      requestsCancelTokenSource.current,
      productsToShow
    );
  }, [createProductItemRows, priceTags, productItems]);

  // memoize the parent rows
  const expandableRowKeys = useMemo(
    () => visibleProductGroupRows.map((parentRow: any) => parentRow.key),
    [visibleProductGroupRows]
  );

  /**
   * update pagination
   * @param {number} page
   */
  const paginationOnChange = (page: number) => {
    elementScrollToPosition({ elementToScroll: window });

    setUpdateUrlFragments({
      context: "pagination",
      parameters: {
        page,
      },
    });
  };

  /**
   * delete all entries from priceTagList
   */
  const deletePriceTagListEntries = () => {
    setIsLoading(true);

    deleteProductsFromPriceTagList({
      cancelTokenSource: postCancelTokenSource.current,
      sku: ["*"],
    })
      .then(() => {
        setSelectedRowKeys([]);
        setProductItems([]);
        setPaginationData({
          current: 1,
          total: 0,
        });
        setIsLoading(false);
        setClearModalVisible(false);
        message.success(messageData.success.priceTagList.removeAllItems);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          message.error(messageData.error.unexpected);
        }
        requestCatchHandler(error);
      });
  };

  const deleteSelectedItems = () => {
    setIsLoading(true);
    const updatedListProductItems = productItems.filter(
      (item) => !selectedRowKeys.includes(item?.sku)
    );
    deleteProductsFromPriceTagList({
      cancelTokenSource: postCancelTokenSource.current,
      sku: selectedRowKeys,
      deleteSelected: true,
    })
      .then(() => {
        setSelectedRowKeys([]);
        setProductItems(updatedListProductItems);
        setIsLoading(false);
        setShowDeleteSelectedItemModal(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          message.error(messageData.error.unexpected);
        }
        requestCatchHandler(error);
      });
  };

  /**
   * Init price date
   */
  useEffect(() => {
    // set price date on first session load or if date is too old
    if (!priceDate || disabledDatePickerDates(moment(priceDate))) {
      setPriceRequestDate(deliveryDate);
    }
  }, [priceDate, deliveryDate, setPriceRequestDate]);

  /**
   * Fetch products on date change
   */
  useEffect(() => {
    if (priceDate) {
      getLabelProducts();
    }
  }, [priceDate, getLabelProducts]);

  useEffect(() => {
    const productPrice = productItems.map(({ prices }: ProductData) => {
      return !prices.length;
    });
    if (productPrice.includes(true)) {
      message.warning(messageData.warning.priceTagList);
    }
  }, [productItems]);

  /**
   * update price field with values, in fact, the redux store gets updated
   *
   * @param {string | null} valueType
   * @param {boolean} isCounterPrice
   */
  const autofillList = (
    valueType: "rrPrice" | "rrCalcPrice" | "reset",
    isCounterPrice = false
  ) => {
    updateCancelTokenSource.current = cancelAndRenewCancelToken(
      updateCancelTokenSource.current
    );

    if (!priceTags?.length || !valueType) {
      return false;
    }

    // create a new object for redux
    const prefilledPriceTags = productItems.map(
      ({ prices, sku, attributes }: ProductData) => {
        if (valueType === "reset") {
          return {
            sku,
            price: null,
          };
        }
        const datePrice = prices.find(
          (priceEntry) => priceEntry.deliveryDate === priceDate
        );

        if (!datePrice) {
          return {
            sku,
            price: null,
          };
        }

        const {
          [productAttributes.unitQuantity]: productUnitQuantity,
          [productAttributes.weighingArticle]: weighingArticle,
        } = attributes;

        let price = getProductUVP(
          datePrice[valueType],
          productUnitQuantity || 1,
          weighingArticle === "1"
        );

        if (isCounterPrice) {
          price = Math.round(price / 10);
        }

        return {
          sku,
          price,
        };
      }
    );

    if (!prefilledPriceTags?.length) {
      return true;
    }

    setIsUpdating(true);

    updateProductsOnPriceTagList({
      priceTags: prefilledPriceTags,
      cancelTokenSource: updateCancelTokenSource.current,
    })
      .then(() => {
        setIsUpdating(false);
        dispatch({
          type: "priceTagList/set-prefilled-list",
          payload: {
            priceTags: prefilledPriceTags,
          },
        });
        const messageKey = `${valueType}${isCounterPrice ? "Counter" : ""}`;
        // eslint-disable-next-line
        // @ts-ignore
        message.success(messageData.success.priceTagList.autofill[messageKey]);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsUpdating(false);
        }
        requestCatchHandler(error);
      });

    return true;
  };

  const autofillDropdownMenuItems: ItemType[] = [
    {
      label: "Kalkulatorische UVP einsetzen",
      key: "autofill_action_1",
      onClick: () => {
        autofillList("rrCalcPrice");
      },
    } as ItemType,
    {
      label: "Kalkulatorische UVP 100g Thekenware einsetzen",
      key: "autofill_action_2",
      onClick: () => {
        autofillList("rrCalcPrice", true);
      },
    } as ItemType,
    {
      label: "UVP Preise einsetzen",
      key: "autofill_action_3",
      onClick: () => autofillList("rrPrice"),
    } as ItemType,
    {
      label: "UVP 100g Thekenware einsetzen",
      key: "autofill_action_4",
      onClick: () => autofillList("rrPrice", true),
    } as ItemType,
    {
      label: "Alle Preise leeren",
      key: "autofill_action_5",
      onClick: () => autofillList("reset"),
    } as ItemType,
  ];

  const actionDropdownMenuItems: ItemType[] = [
    {
      label: "Blanko Etiketten erstellen",
      key: "action_1",
      onClick: () => setBlankModalVisible(true),
    } as ItemType,
    {
      label: "Etiketten drucken",
      key: "action_2",
      onClick: () => setPrintModalVisible(true),
      disabled: !hasItemsOnPriceTagList || !selectedRowKeys?.length,
    } as ItemType,
    {
      label: "Schilder bestellen",
      key: "action_3",
      onClick: () => setOrderModalVisible(true),
      disabled:
        !hasItemsOnPriceTagList ||
        !selectedRowKeys?.length ||
        !contactPerson?.email,
    } as ItemType,
    {
      label: "Markierte Artikel löschen",
      key: "action_4",
      onClick: () => setShowDeleteSelectedItemModal(true),
      disabled: !selectedRowKeys?.length,
    } as ItemType,
  ];

  /**
   * update the redux state based on the updated view
   * @param rows {Record<any, any>[]}
   * @param selected {boolean}
   */
  const updateSelectedSkus = (rows: Record<any, any>[], selected: boolean) => {
    // get the keys of all rows, use SKU for later reuseability of list
    const interactedRowKeys = rows?.map((row) => row.sku);
    if (rows && Array.isArray(rows)) {
      rows.forEach(({ sku }) => {
        dispatch({
          type: selected
            ? "priceTagList/add-selected-priceTag-item"
            : "priceTagList/delete-selected-priceTag-item",
          payload: sku,
        });
      });
    }
    /*
     * If the row was selected, it is save to assume, that the keys should be added.
     * Make use of Set(), where every entry is automatically unique.
     * Make sure, the iterator of Set is parsed back to an array to assure type safety.
     * Otherwise filter all selected keys from the current selected array of strings and update with the delta.
     */
    if (selected) {
      const newRowKeySet = new Set([...selectedRowKeys, ...interactedRowKeys]);
      setSelectedRowKeys(Array.from(newRowKeySet));
    } else {
      const deltaRowKeys = selectedRowKeys.filter(
        (rowKey) => !interactedRowKeys.includes(rowKey)
      );

      setSelectedRowKeys(deltaRowKeys);
    }
  };

  /**
   * trigger print call after button click
   * @param event {React.MouseEvent<HTMLButtonElement>}
   */
  const printPriceTagLabel = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const messageKey = `priceTagListPrint_${Date.now()}`;
    setIsGeneratingPrint(true);

    message.loading({
      ...messageData.loading.priceTagList.print,
      key: messageKey,
    });

    /*
     * filter list by selected SKUs
     * then parse the stringified price to a cent based integer
     */
    const priceTagsList = priceTags.filter((priceTag: PriceTagListEntry) =>
      selectedRowKeys.includes(priceTag?.sku)
    );

    return postPrintPriceTagList({
      cancelTokenSource: postCancelTokenSource.current,
      deliveryDate: priceDate,
      printType: event.currentTarget.value,
      priceTagsList,
    })
      .then((blobResponse) => {
        try {
          const fileUrl = window.URL.createObjectURL(blobResponse.data);
          /*
           * filename is part of the standardized "content-disposition" header
           * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition}
           */
          const fileName =
            blobResponse.headers?.["content-disposition"].split(
              "filename="
            )?.[1];
          const link = document.createElement("a");

          link.href = fileUrl;
          link.download = fileName;
          link.click();

          // make sure to release memory
          setTimeout(() => window.URL.revokeObjectURL(fileUrl), 0);

          setIsGeneratingPrint(false);
          setPrintModalVisible(false);

          message.success({
            ...messageData.success.priceTagList.print,
            key: messageKey,
          });
        } catch (downloadError) {
          return Promise.reject(downloadError);
        }

        return blobResponse;
      })
      .catch((error) => {
        setIsGeneratingPrint(false);

        if (!axios.isCancel(error)) {
          message.error({
            ...messageData.error.priceTagList.print,
            key: messageKey,
          });
        }

        requestCatchHandler(error);
      });
  };

  /*
   * Set content for textarea in price tags order modal
   */
  useEffect(() => {
    let content = "";
    allProductItems
      .filter((productItemShort: ProductItemShort) => {
        return selectedRowKeys.includes(productItemShort.sku);
      })
      .forEach((productItemShort: ProductItemShort) => {
        content += `${productItemShort.name}, ${productItemShort.sku}\n`;
      });
    setOrderContent(content);
  }, [selectedRowKeys, allProductItems, orderContentReset]);

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true;
      refreshCancelTokenSource.current = cancelAndRenewCancelToken(
        refreshCancelTokenSource.current
      );
      setPriceTagList(refreshCancelTokenSource.current).catch((error) =>
        requestCatchHandler(error)
      );
    }

    return () => {
      hasMountedRef.current = false;
    };
  }, []);

  /*
   * locale object of table
   * emptyText: the elements, which will be displayed if the table is empty
   */
  const locale = {
    emptyText: (
      <EmptyListContent description="Keine Produkte auf der Etikettenliste." />
    ),
  };

  const priceTagListHeaderContent = (
    <Row className="price-tag-list-header">
      <BackButton />
      <Col span={12}>
        <header>
          <div>
            <h1>Etikettenliste</h1>
            {hasItemsOnPriceTagList && (
              <button
                className="button buttonText buttonWithIcon buttonTextDecoration--inverted buttonCartDelete button__clear-price-tag-list"
                type="button"
                onClick={() => setClearModalVisible(true)}
              >
                <Delete className="icon" />
                Etikettenliste leeren
              </button>
            )}
          </div>
          <DateSwitcher
            label="Preisdatum"
            deliveryDate={priceDate}
            dateSwitchCallback={(updatedDate) =>
              setPriceRequestDate(updatedDate)
            }
          />
        </header>

        <HrDivider size={2} spacingTop="m" spacingBottom="m" />

        <div className="hidden-md-up-max">
          <DropdownButton
            items={autofillDropdownMenuItems}
            disabled={!hasItemsOnPriceTagList}
            fullWidth
            className="mb-m"
          />
        </div>

        <div className="hidden-md-down">
          {autofillDropdownMenuItems.map((menuItem: any) => (
            <Button
              key={menuItem.key}
              className="button buttonPrimary buttonPrimary--inverted mb-s mr-s"
              disabled={!hasItemsOnPriceTagList}
              onClick={menuItem.onClick}
            >
              {menuItem.label}
            </Button>
          ))}
        </div>

        <HrDivider size={2} spacingTop="s" spacingBottom="m" />

        <div className="hidden-md-up-max">
          <DropdownButton
            items={actionDropdownMenuItems}
            fullWidth
            className="mb-m"
          />
        </div>

        <div className="hidden-md-down mb-sm">
          {actionDropdownMenuItems.map((menuItem: any) => (
            <Button
              key={menuItem.key}
              className="button buttonPrimary mb-s mr-s"
              disabled={menuItem.disabled || false}
              onClick={menuItem.onClick}
            >
              {menuItem.label}
            </Button>
          ))}
        </div>
      </Col>
    </Row>
  );

  return (
    <>
      <TrackingHelmet
        title={pageTitles.priceTagList}
        suffix={pageTitleSuffix}
      />

      <ShopCategoryNavigation />

      <Layout className="container-layout container-layout--inner">
        {isXsScreen ? (
          priceTagListHeaderContent
        ) : (
          <Affix offsetTop={90}>{priceTagListHeaderContent}</Affix>
        )}

        <ProductItemTable
          columns={columns}
          expandableRowKeys={expandableRowKeys}
          isLoading={isLoading || isUpdating}
          itemRowClassName="priceTagListItem"
          locale={locale}
          productItemRows={visibleProductGroupRows}
          selectedRowKeys={selectedPriceTagItems}
          updateSelectedRows={updateSelectedSkus}
          pagination={{
            ...paginationData,
            onChange: paginationOnChange,
          }}
          rightHeaderComponent={
            <QuickSelectProductInput
              isLoading={isLoading}
              onSubmitValue={onSubmitValue}
              checkIfAlreadyOnList={isItemOnPriceTagList}
              isAlreadyOnListWarning="ist bereits auf der Etikettenliste"
              type="priceTagList"
            />
          }
          tableClassName="tableGroupSpacing"
        />
      </Layout>

      <ButtonBackToTop />

      <Modal
        title="Formate"
        footer={null}
        visible={printModalVisible}
        onCancel={() => {
          setPrintModalVisible(false);
        }}
        width={600}
        className="price-tag-modal"
      >
        {printTypes?.length > 0 ? (
          <Spin size="default" spinning={isGeneratingPrint}>
            <p>Welche Schilder sollen erstellt bzw. bestellt werden?</p>
            <ul className="list list--inline list--unstyled priceTagListTagContent">
              {printTypes.map(({ label, type }: PriceTagListPrintType) => (
                <li className="listEntry" key={`${label}_${type}`}>
                  <button
                    type="button"
                    className="button buttonPrimary buttonPrimary--inverted mb-s mr-s"
                    value={type}
                    onClick={printPriceTagLabel}
                  >
                    {label}
                  </button>
                </li>
              ))}
            </ul>
          </Spin>
        ) : (
          <p>Es konnten keine Etikettenvorlagen geladen werden.</p>
        )}
      </Modal>

      <Modal
        title="Etikettenliste leeren?"
        confirmLoading={isLoading}
        visible={clearModalVisible}
        cancelText="Abbrechen"
        onCancel={() => {
          setClearModalVisible(false);
        }}
        okText="Ja, bitte leeren"
        onOk={deletePriceTagListEntries}
      >
        Möchten Sie wirklich die gesamte Etikettenliste leeren?
      </Modal>

      <Modal
        title="Markierte Artikel löschen?"
        confirmLoading={isLoading}
        visible={showDeleteSelectedItemModal}
        cancelText="Abbrechen"
        onCancel={() => setShowDeleteSelectedItemModal(false)}
        okText="Ja, bitte löschen"
        onOk={deleteSelectedItems}
      >
        <p>Möchten Sie wirklich die markierten Artikel löschen?</p>
      </Modal>

      <Modal
        title="Formate"
        footer={null}
        visible={blankModalVisible}
        onCancel={() => {
          setBlankModalVisible(false);
        }}
        width={800}
        className="blank-price-tag-modal"
      >
        <p>Welche Schilder sollen erstellt bzw. bestellt werden?</p>
        <div className="linked-thumbnails">
          <LinkedThumbnail
            href={PriceTagCreamCheese}
            thumbnailSrc={CoverImageCreamCheese}
            linkText="Frischkäsezubereitung"
            altText="Vorschau Frischkäse Etiketten PDF"
            buttonClassName="button buttonPrimary"
          />

          <LinkedThumbnail
            href={PriceTagOgBlack}
            thumbnailSrc={CoverImageOgBlack}
            linkText="Obst & Gemüse"
            altText="Obst & Gemüse Etiketten PDF"
            buttonClassName="button buttonPrimary"
          />

          <LinkedThumbnail
            href={PriceTagOgWhite}
            thumbnailSrc={CoverImageOgWhite}
            linkText="Obst & Gemüse (weiß)"
            altText="Obst & Gemüse (weiß) Etiketten PDF"
            buttonClassName="button buttonPrimary"
          />

          <LinkedThumbnail
            href={PriceTagWeeklyOfferBioladen}
            thumbnailSrc={CoverImageWeeklyOfferBioladen}
            linkText="Wochenaktion (bioladen*)"
            altText="Wochenaktion (bioladen*) Etiketten PDF"
            buttonClassName="button buttonPrimary"
          />

          <LinkedThumbnail
            href={PriceTagWeeklyOfferBlank}
            thumbnailSrc={CoverImageWeeklyOfferBlank}
            linkText="Wochenaktion (blanko)"
            altText="Wochenaktion (blanko) Etiketten PDF"
            buttonClassName="button buttonPrimary"
          />

          <LinkedThumbnail
            href={PriceTagWeeklyOfferLong}
            thumbnailSrc={CoverImageWeeklyOfferLong}
            linkText="Wochenaktion (lang)"
            altText="Wochenaktion (lang) Etiketten PDF"
            buttonClassName="button buttonPrimary"
          />
        </div>
      </Modal>

      <Modal
        title="Schilderbestellung"
        footer={null}
        visible={orderModalVisible}
        onCancel={() => setOrderModalVisible(false)}
        width={800}
        className="order-price-tag-modal"
        destroyOnClose
        forceRender
      >
        <p>Eine E-Mail mit folgendem Inhalt an die Kundenbetreuung senden:</p>
        <TextArea
          rows={12}
          value={orderContent}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setOrderContent(event.target.value)
          }
        />
        <Button
          onClick={() => {
            setIsOrderProcessing(true);
            const cancelTokenSource = getCancelTokenSource();
            postOrderPriceTags({
              content: orderContent,
              cancelTokenSource,
            })
              .then(() => {
                setIsOrderProcessing(false);
                setOrderModalVisible(false);
                message.success(messageData.success.priceTagList.order);
                setOrderContentReset((prevState: boolean) => !prevState);
              })
              .catch((error) => {
                if (!axios.isCancel(error)) {
                  setIsOrderProcessing(false);
                  setOrderModalVisible(false);
                  message.error(messageData.error.priceTagList.order);
                }
                requestCatchHandler(error);
              });
          }}
          className="button buttonPrimary mt-sm mb-sm mr-sm"
          disabled={!orderContent}
          loading={isOrderProcessing}
        >
          Schilder bestellen
        </Button>
        <Button
          onClick={() =>
            setOrderContentReset((prevState: boolean) => !prevState)
          }
          className="button buttonText mt-sm mb-sm"
        >
          Zurücksetzen
        </Button>
      </Modal>
    </>
  );
};

export default PriceTagList;
