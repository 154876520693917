import React from "react";

import { useSelector } from "react-redux";

import { ReactComponent as SupportAltIcon } from "../../../static/svg/support-alternative.svg";
import { RootState } from "../../../types/rootState";

interface PrintViewSupportProps {
  isFavouriteList: boolean;
}

const PrintViewSupport: React.FC<PrintViewSupportProps> = (
  props: PrintViewSupportProps
) => {
  const { isFavouriteList = false } = props;

  const { phone = "" } =
    useSelector((state: RootState) => state?.userData?.contactPerson) || {};

  return (
    <div className="print-view-support">
      <SupportAltIcon className="print-view-support__support-icon" />
      {isFavouriteList ? (
        <span>
          SIE HABEN FRAGEN?
          <br />
          Telefon: {phone}
        </span>
      ) : (
        <span>
          SIE HABEN FRAGEN ZU IHRER BESTELLUNG?
          <br />
          Telefon: 02541 / 747-1410
        </span>
      )}
    </div>
  );
};

export default PrintViewSupport;
