import { BusinessUnit } from "../../../types/businessUnits";

const sortByOwnershipAndKey = (
  bu1: BusinessUnit,
  bu2: BusinessUnit,
  defaultBusinessUnit: BusinessUnit
) => {
  const defaultCompanyId = defaultBusinessUnit?.idCompany;

  const isForeignBusinessUnitBu1 = bu1?.idCompany !== defaultCompanyId;
  const isForeignBusinessUnitBu2 = bu2?.idCompany !== defaultCompanyId;

  const businessUnitKeyBu1 = Number(bu1?.companyBusinessUnitKey || 0);
  const businessUnitKeyBu2 = Number(bu2?.companyBusinessUnitKey || 0);

  return (
    Number(isForeignBusinessUnitBu1) - Number(isForeignBusinessUnitBu2) ||
    businessUnitKeyBu1 - businessUnitKeyBu2
  );
};

const filterByAddressAndKey = (array: any, searchString: string) => {
  return array.filter((o: any) =>
    Object.keys(o).some((k) => {
      if (k === "companyBusinessUnitKey" || k === "address1" || k === "city") {
        return String(o[k]).toLowerCase().includes(searchString.toLowerCase());
      }
      return null;
    })
  );
};

export { sortByOwnershipAndKey, filterByAddressAndKey };
