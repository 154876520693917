import React from "react";

import clsx from "clsx";
import { Descriptions } from "antd";
import { StandingOrderRequestStateItem } from "../../../../../../types/standingOrder";

interface StandingOrderDetailsProps {
  item: StandingOrderRequestStateItem;
  className?: string;
}

const StandingOrderDetails: React.FC<StandingOrderDetailsProps> = (
  props: StandingOrderDetailsProps
) => {
  const { item, className } = props;

  return (
    <div className={clsx("standing-order-details", className)}>
      <Descriptions>
        <Descriptions.Item label="Menge">
          {item?.quantity ?? "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Liefertag">
          {item?.weekdayShipmentDate ?? "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Intervall">
          {item?.interval ?? "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Start">
          {item?.startDate ?? "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Ende">
          {item?.endDate ?? "-"}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default StandingOrderDetails;
