import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";
import clsx from "clsx";

import { Button } from "antd";
import BusinessUnitModal from "../../molecules/BusinessUnitModal";
import useGetCustomerDisplayName from "../../../hooks/useGetCustomerDisplayName";
import useIsForeignCompanyBusinessUnit from "../../../hooks/useIsForeignCompanyBusinessUnit";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import { getGreeting } from "./utils";
import { RootState } from "../../../types/rootState";
import { BusinessUnit } from "../../../types/businessUnits";
import { ReactComponent as StoreIcon } from "../../../static/svg/store.svg";

interface BusinessUnitBoxProps {
  className: string;
}

const BusinessUnitBox: React.FC<BusinessUnitBoxProps> = (
  props: BusinessUnitBoxProps
) => {
  const { className } = props;

  const { isForeignCompanyBusinessUnit } = useIsForeignCompanyBusinessUnit();
  const { customerDisplayName } = useGetCustomerDisplayName();
  const isLargeScreen = useMedia(`(min-width: ${getCssVariable("screen-lg")})`);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const {
    businessUnit,
    businessUnits,
  }: { businessUnit: BusinessUnit; businessUnits: BusinessUnit[] } =
    useSelector((state: RootState) => state?.userData);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <>
      <Button
        ref={buttonRef}
        className={clsx(
          "business-unit-box",
          businessUnits?.length > 1 && "can-switch",
          isForeignCompanyBusinessUnit ? "is-foreign" : "is-own",
          isLargeScreen ? "large" : "small",
          customerDisplayName ? "has-customer-name" : "has-no-customer-name",
          className
        )}
        onClick={() => {
          setIsVisible(true);
          buttonRef?.current?.blur();
        }}
        disabled={businessUnits?.length <= 1}
      >
        {!isLargeScreen && !customerDisplayName && (
          <StoreIcon className="icon icon-business-unit" />
        )}

        {!isLargeScreen && !!customerDisplayName && customerDisplayName}

        {isLargeScreen && !isForeignCompanyBusinessUnit && (
          <div className="business-unit-box__inner">
            <div className="business-unit-box__inner__left">
              <div>Kunde</div>
              <div>{businessUnit.companyBusinessUnitKey}</div>
            </div>
            <div className="business-unit-box__inner__separator">&nbsp;</div>
            <div className="business-unit-box__inner__right">
              {!!customerDisplayName && (
                <>
                  <div>{`${getGreeting()},`}</div>
                  <div>{customerDisplayName}</div>
                </>
              )}
              {!customerDisplayName && <div>{`${getGreeting()}`}</div>}
            </div>
          </div>
        )}

        {isLargeScreen && !!isForeignCompanyBusinessUnit && (
          <div className="business-unit-box__inner">
            <div className="business-unit-box__inner__right">
              <div>Auftrag für den Kunden</div>
              <div>
                {businessUnit?.companyBusinessUnitKey || "-"} |{" "}
                {businessUnit?.companyName || "-"}
              </div>
            </div>
          </div>
        )}
      </Button>

      <BusinessUnitModal
        hideModal={() => {
          setIsVisible(false);
        }}
        visible={isVisible}
        businessUnit={businessUnit}
        closable
      />
    </>
  );
};

export default BusinessUnitBox;
