import React from "react";

import { Link } from "react-router-dom";
import moment from "moment";

import parseProductAttributes from "../../../product/parseProductAttributes";
import Price from "../../../price/Price";
import { getWeekdayShipmentDateFullName } from "./utils";
import { routePathNames } from "../../../../appConfig";
import { productAttributes } from "../../../../api/productAttributes";
import { fullDateFormat } from "../../../../utils/dateFormats";

export const columns: any = [
  {
    title: "Art.-Nr.",
    width: 60,
    dataIndex: "sku",
    sorter: true,
  },
  {
    title: "Artikelbezeichnung",
    width: 220,
    dataIndex: "sku",
    render: (sku: string, record: any) => {
      const { name = "-" } = record?.product || {};

      if (!sku) {
        return <>{name}</>;
      }

      return (
        <Link to={`${routePathNames.product}${sku}`} className="product-link">
          {name}
        </Link>
      );
    },
  },
  {
    title: "Anzahl",
    width: 70,
    dataIndex: "quantity",
    sorter: true,
  },
  {
    title: "VPE",
    width: 175,
    dataIndex: "vpe",

    render: (_vpe: string, record: any) => {
      const { product = {} } = record;
      const { attributes = {} } = product || {};

      const {
        [productAttributes.package]: vpeText,
        [productAttributes.unitQuantity]: productUnitQuantity,
      } = attributes;

      const unitQuantity = parseProductAttributes(productUnitQuantity);

      if (!vpeText && !unitQuantity) {
        return "-";
      }

      return (
        <span className="text-sm text-bold">
          {`${unitQuantity || ""}${vpeText ? ` x ${vpeText}` : ""}`}
        </span>
      );
    },
  },
  {
    title: "Liefertag",
    width: 150,
    dataIndex: "weekdayShipmentDateSorting",
    render: (weekdayShipmentDateSorting: number) =>
      getWeekdayShipmentDateFullName(weekdayShipmentDateSorting),
    sorter: true,
  },
  {
    title: "Intervall",
    width: 150,
    dataIndex: "interval",
    render: (interval: string) => interval || "-",
  },
  {
    title: "Startdatum",
    width: 150,
    dataIndex: "startDate",
    render: (startDate: string) =>
      startDate ? moment(startDate).format(fullDateFormat) : "-",
    sorter: true,
  },
  {
    title: "Enddatum",
    width: 150,
    dataIndex: "endDate",
    render: (endDate: string) =>
      endDate ? moment(endDate).format(fullDateFormat) : "-",
    sorter: true,
  },
  {
    title: "Einzelpreis",
    width: 120,
    dataIndex: "sku",
    render: (_sku: string, record: any) =>
      record?.price?.tiers?.[0]?.price ? (
        <Price
          tag="span"
          className="text-sm text-bold"
          price={record?.price?.tiers?.[0]?.price}
        />
      ) : (
        "-"
      ),
  },
  {
    title: "Gesamtsumme",
    width: 120,
    dataIndex: "quantity",
    fixed: "right",
    render: (quantity: number, record: any) =>
      record?.price?.tiers?.[0]?.price && quantity ? (
        <Price
          tag="span"
          className="text-sm text-bold"
          price={
            Number(record?.price?.tiers?.[0]?.price || 0) * Number(quantity)
          }
        />
      ) : (
        "-"
      ),
  },
];
