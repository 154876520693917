import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import clsx from "clsx";
import axios, { CancelTokenSource } from "axios";
import { Alert, Button, Col, message, Modal, Row } from "antd";

import { useForm } from "antd/lib/form/Form";
import { ProductImage } from "../../../product";
import {
  getPriceFromFormValue,
  getQuantityFromFormValue,
} from "../StocktakingForm/utils";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import postStocktakingItem from "../../../../api/stocktaking/postStocktakingItem";
import StocktakingForm from "../StocktakingForm";
import useCancelAxiosOnUnmount from "../../../../hooks/useCancelAxiosOnUnmount";
import getDefaultImageUrlSet from "../../../../utils/getDefaultImageUrlSet";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../../api/getCancelTokenSource";
import { messageData } from "../../../../appConfig";
import { productAttributes } from "../../../../api/productAttributes";
import { ProductData } from "../../../../types/productData";
import getDeliveryDateBasedAttributes from "../../../product/getDeliveryDateBasedAttributes";
import { getValidDeliveryDate } from "../../../../utils/datePicker";
import { apiDateFormat } from "../../../../utils/dateFormats";

interface StocktakingItemPostModalProps {
  stocktakingId: number;
  eanCode: string;
  productData: ProductData;
  className?: string;
  isVisible?: boolean;
  setVisibleCallback?: (value: boolean) => void;
  onSave?: () => void;
  onError?: () => void;
}

const StocktakingItemPostModal: React.FC<StocktakingItemPostModalProps> = (
  props: StocktakingItemPostModalProps
) => {
  const {
    stocktakingId,
    eanCode,
    productData,
    className,
    isVisible,
    setVisibleCallback = () => {},
    onSave = () => {},
    onError = () => {},
  } = props;

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [form] = useForm();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [quantitySuffix, setQuantitySuffix] = useState<string>(null);

  const isKnownProduct: boolean = useMemo(
    () => !!productData?.name && !!productData?.sku,
    [productData]
  );

  const setFormFields = useCallback(() => {
    const { attributes, prices } = productData || {};
    const {
      [productAttributes.vatGroup]: vatGroup,
      [productAttributes.unitQuantity]: productUnitQuantity,
      [productAttributes.priceUnitText]: priceUnitText,
      [productAttributes.weighingArticle]: weighingArticle,
    } = attributes || {};
    const taxRate = Number(vatGroup?.replace("%", "")?.trim()) || 19;
    const basePriceQuantityNumber = parseFloat(productUnitQuantity) || 1;
    const { defaultPrice } = getDeliveryDateBasedAttributes({
      deliveryDate: getValidDeliveryDate().format(apiDateFormat),
      prices,
    });
    setQuantitySuffix(priceUnitText);

    form.setFields([
      { name: "label", value: productData?.name },
      { name: "quantity", value: "" },
      {
        name: "unitPrice",
        value: defaultPrice
          ? Math.floor(
              weighingArticle === "1"
                ? defaultPrice
                : defaultPrice / basePriceQuantityNumber
            ) / 100
          : "",
      },
      { name: "taxRate", value: taxRate },
      { name: "producer", value: productData?.brand },
      { name: "sku", value: productData?.sku },
    ]);
  }, [form, productData]);

  const handlePost = () => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );
    setIsSaving(true);
    setHasErrors(false);
    postStocktakingItem({
      stocktakingId,
      eanCode,
      label: form.getFieldValue("label"),
      quantity:
        getQuantityFromFormValue(form.getFieldValue("quantity")) || undefined,
      unitPrice:
        getPriceFromFormValue(form.getFieldValue("unitPrice")) || undefined,
      unitVpe:
        productData?.vpeQuantity && productData?.vpeText
          ? `${productData.vpeQuantity}x ${productData.vpeText}`
          : undefined,
      taxRate: form.getFieldValue("taxRate") || undefined,
      producer: form.getFieldValue("producer"),
      sku: form.getFieldValue("sku"),
      idProduct: Number(productData?.idProductConcrete) || null,
      cancelTokenSource: cancelTokenSource.current,
    })
      .then(() => {
        setIsSaving(false);
        setVisibleCallback(false);
        setFormFields();
        onSave();
        message.success({
          ...messageData.success.stocktakingItems.createSuccess,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsSaving(false);
          setVisibleCallback(false);
          setFormFields();
          onError();
          message.error({ ...messageData.error.stocktakingItems.createError });
        }
        requestCatchHandler(error);
      });
  };

  useEffect(() => {
    setFormFields();
  }, [setFormFields]);

  return (
    <Modal
      forceRender
      className={clsx("stocktaking-item-post-modal", className)}
      visible={isVisible}
      title="Artikel hinzufügen"
      onCancel={() => {
        setVisibleCallback(false);
      }}
      destroyOnClose
      width={800}
      key={`modal_stocktaking_post_${productData?.sku || ""}`}
      footer={
        <Row>
          <Col span={12} className="flex items-center justify-end sm-flex-col">
            <Button
              className="button buttonPrimary buttonText sm-with-full sm-mb-m mb-0 mr-s sm-mr-0"
              onClick={() => {
                setVisibleCallback(false);
              }}
            >
              Abbrechen
            </Button>
            <Button
              loading={isSaving}
              disabled={hasErrors}
              className="button buttonPrimary buttonWithSpin sm-with-full sm-mb-m mb-0 mr-s sm-mr-0"
              onClick={() => {
                form.submit();
              }}
            >
              Hinzufügen
            </Button>
          </Col>
        </Row>
      }
    >
      <Row>
        {!isKnownProduct && (
          <Col xs={12} className="mb-m">
            <Alert
              message="Dieser Artikel ist unbekannt. Er kann trotzdem zur Inventur hinzugefügt werden. Bitte geben Sie dazu nachfolgend bekannte Produktdetails ein."
              type="warning"
              showIcon
            />
          </Col>
        )}
        {!!isKnownProduct && (
          <Col xs={12} sm={3}>
            <ProductImage
              imageSet={getDefaultImageUrlSet(productData?.imageSets)}
              imageType="thumbnail"
            />
          </Col>
        )}
        <Col xs={12} sm={isKnownProduct ? 9 : 12}>
          <StocktakingForm
            formKey={`form_stocktaking_post_${productData?.sku}`}
            form={form}
            isVisible={isVisible}
            isUpdateMode={false}
            hasProduct={isKnownProduct}
            onFinish={handlePost}
            onFinishFailed={() => {
              setHasErrors(true);
            }}
            onFieldsChange={() => {
              setHasErrors(
                !!form.getFieldsError()?.find((field) => {
                  return !!field.errors.length;
                })
              );
            }}
            quantitySuffix={quantitySuffix}
            hasErrors={hasErrors}
            isSaving={isSaving}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default StocktakingItemPostModal;
