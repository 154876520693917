import { CancelTokenSource } from "axios";
import updateLocalCartData from "./updateLocalCartData";
import getCart from "../../api/cart/getCart";
import storeDispatch from "./storeDispatch";
import { ProductData } from "../../types/productData";

interface SetLocalCartProps {
  deliveryDate: ProductData["deliveryDate"];
  cancelTokenSource?: CancelTokenSource;
}

/**
 * gets a cart or creates one based on the given date
 * than stores the cart data to the redux
 * @param deliveryDate {string}
 * @param cancelTokenSource {CancelTokenSource}
 * @return {Promise}
 */
const setLocalCart = async ({
  deliveryDate,
  cancelTokenSource,
}: SetLocalCartProps) =>
  getCart({
    deliveryDate,
    cancelTokenSource,
  }).then((cartRes: any) => {
    if (!cartRes || !cartRes?.data?.data || cartRes.status >= 300) {
      return Promise.reject(cartRes);
    }

    const {
      headers: { etag },
      data: { data, included },
    } = cartRes;

    /*
     * if a cart gets first created, the returned value
     * is only an Object / a Record, but
     * if the cart existed, an array is returned,
     * so use the first entry which is the cart
     */
    let cartData = data;

    if (Array.isArray(data)) {
      [cartData] = data;
    }

    // store data to redux
    updateLocalCartData(cartData, etag);

    // store cart items to redux, if any are present
    storeDispatch("cart/set-cart", {
      cartId: data?.id,
      included,
      companyBusinessUnitKey: data?.attributes?.companyBusinessUnitKey || "",
      employeeDiscount: data?.attributes?.employeeDiscount,
    });

    return cartRes;
  });

export default setLocalCart;
